import React, { FC } from 'react'

const SVGS: any = {
  cartIcon: (
    <svg className="w-full h-full" viewBox="0 0 56 56" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-670.000000, -224.000000)">
          <g transform="translate(0.000000, 208.000000)">
            <g transform="translate(590.000000, 16.000000)">
              <g transform="translate(80.000000, 0.000000)">
                <g transform="translate(7.000000, 9.000000)">
                  <path
                    d="M0,-1.24344979e-14 L4.83074189,-1.24344979e-14 C5.30113704,-1.22988635e-14 5.70793841,0.327840892 5.80790119,0.78749189 L11.8500425,28.5705945 L11.8500425,28.5705945 L36.2682204,28.5705945 L40.1673152,7.71230186 L8.20014931,7.71230186"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinejoin="round"
                  />
                  <circle
                    fill="#000000"
                    cx="15.5635729"
                    cy="36.5179648"
                    r="2.72066746"
                  />
                  <circle
                    fill="#000000"
                    cx="31.5703296"
                    cy="36.5179648"
                    r="2.72066746"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  guarantee: (
    <svg className="w-full h-full" viewBox="0 0 40 40" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-24.000000, -2035.000000)"
          stroke="#66BC37"
          strokeWidth="3"
        >
          <g transform="translate(0.000000, 127.000000)">
            <g transform="translate(0.000000, 1499.000000)">
              <g transform="translate(0.000000, 52.000000)">
                <g transform="translate(0.000000, 336.000000)">
                  <g transform="translate(24.000000, 0.000000)">
                    <g transform="translate(0.000000, 21.000000)">
                      <g transform="translate(7.000000, 5.000000)">
                        <path d="M13.5,0 L0,4.5 L0,12.525 C0.00347328474,18.7280269 3.59982757,24.4923961 9.5175,27.78 L13.5,30 L17.4825,27.78 C23.4001724,24.4923961 26.9965267,18.7280269 27,12.525 L27,4.5 L13.5,0 Z" />
                        <polyline points="8.53181557 14.4011624 12.6307616 18.0095533 18.5318156 10.0095533" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  logistics: (
    <svg className="w-full h-full" viewBox="0 0 40 40" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-24.000000, -1738.000000)">
          <g transform="translate(0.000000, 127.000000)">
            <g transform="translate(0.000000, 1499.000000)">
              <g transform="translate(0.000000, 52.000000)">
                <g transform="translate(24.000000, 60.000000)">
                  <g transform="translate(2.000000, 9.500000)">
                    <polygon
                      stroke="#66BC37"
                      strokeWidth="3"
                      points="22.5 3 30.6015876 3 35.5 9.58195839 35.5 19 22.5 19"
                    />
                    <polyline
                      stroke="#66BC37"
                      strokeWidth="3"
                      points="3.5 2.10942375e-15 22.5 0 22.5 19 3.5 19"
                    />
                    <circle
                      stroke="#66BC37"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="9.75"
                      cy="19.25"
                      r="2.75"
                    />
                    <circle
                      stroke="#66BC37"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="28.75"
                      cy="19.25"
                      r="2.75"
                    />
                    <polygon
                      fill="#66BC37"
                      fillRule="nonzero"
                      points="9 4.5 9 7.5 0 7.5 0 4.5"
                    />
                    <polygon
                      fill="#66BC37"
                      fillRule="nonzero"
                      points="9 9.5 9 12.5 2 12.5 2 9.5"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  menuIcon: (
    <svg className="w-full h-full" viewBox="0 0 56 56" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-24.000000, -224.000000)"
          stroke="#000000"
          strokeWidth="3"
        >
          <g transform="translate(0.000000, 208.000000)">
            <g transform="translate(24.000000, 16.000000)">
              <g transform="translate(9.000000, 11.000000)">
                <line x1="0" y1="1.11764706" x2="38" y2="1.11764706" />
                <line x1="0" y1="32.4117647" x2="38" y2="32.4117647" />
                <line x1="0" y1="16.7647059" x2="38" y2="16.7647059" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  searchIcon: (
    <svg className="w-full h-full" viewBox="0 0 56 56" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-590.000000, -224.000000)"
          stroke="#000000"
          strokeWidth="3"
        >
          <g transform="translate(0.000000, 208.000000)">
            <g transform="translate(590.000000, 16.000000)">
              <g transform="translate(9.000000, 9.000000)">
                <circle cx="16.1" cy="16.1" r="16.1" />
                <line x1="26.5" y1="26.5" x2="38.4428571" y2="38.4428571" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  smileIcon: (
    <svg className="w-full h-full" viewBox="0 0 56 57" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-510.000000, -225.000000)">
          <g transform="translate(0.000000, 208.000000)">
            <g transform="translate(510.000000, 17.774448)">
              <rect x="8" y="8" width="40" height="40" />
              <path
                d="M28,33.425 C32.9050299,33.425 37.3591653,34.4674101 40.6125558,36.1886673 C43.4602948,37.6953083 45.4571829,39.7139827 45.5701208,42.0818839 L45.5701208,42.0818839 L45.5738248,46.575 L10.425,46.575 L10.425,42.2857143 C10.425,40.8572378 11.1432361,39.5393637 12.3127307,38.3787255 C13.7089783,36.9930512 15.7393766,35.8360492 18.176468,34.9840498 L18.176468,34.9840498 L18.8506538,34.7596066 C21.5368902,33.9089565 24.6636694,33.425 28,33.425 Z"
                stroke="#000000"
                strokeWidth="2.85"
              />
              <circle
                stroke="#000000"
                strokeWidth="3"
                cx="28"
                cy="19"
                r="8.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  shareIcon: (
    <svg className="w-full h-full" viewBox="0 0 56 56" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-670.000000, -1053.000000)"
          stroke="#000000"
          strokeWidth="3"
        >
          <g transform="translate(0.000000, 127.000000)">
            <g transform="translate(24.000000, 926.000000)">
              <g transform="translate(646.000000, 0.000000)">
                <g transform="translate(11.000000, 11.227649)">
                  <polyline
                    strokeLinejoin="round"
                    points="15.3884665 2.61018901 0 2.61018901 0 34.610189 33 34.610189 33 20.7632561"
                  />
                  <polyline
                    transform="translate(31.257439, 7.338119) rotate(85.000000) translate(-31.257439, -7.338119) "
                    points="24.1628439 10.4420041 31.2574385 4.2342338 38.3520331 10.4420041"
                  />
                  <path d="M34.2121334,7.34413645 C30.7803307,7.13303821 28.0009084,7.13103237 25.8738666,7.33811893 C20.5879855,7.8527468 16.9946403,10.2126029 14.4518948,13.070149 C10.9603635,16.9939439 9.30022157,21.5575127 9.47146907,26.7608552" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  freeIcon: (
    <svg className="w-full h-full" viewBox="0 0 40 40" version="1.1">
      <g
        id="icon_freereturns"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(4.000000, 4.000000)" stroke="#66BC37">
          <g
            transform="translate(0.000000, 22.000000)"
            fill="#66BC37"
            fillRule="nonzero"
            strokeWidth="0.5"
          >
            <path d="M2.56666667,9.8 L2.56666667,6.09256757 L5.50817996,6.09256757 L5.50817996,4.47135135 L2.56666667,4.47135135 L2.56666667,2.20918919 L6.7,2.20918919 L6.7,0.5 L0.5,0.5 L0.5,9.8 L2.56666667,9.8 Z M10.1741379,9.8 L10.1741379,6.29364865 L11.304569,6.29364865 L13.1565517,9.8 L15.225,9.8 L13.2407328,6.02972973 C13.8420259,5.83702703 14.3010129,5.53121622 14.617694,5.1122973 C14.934375,4.69337838 15.0927155,4.11527027 15.0927155,3.37797297 C15.0927155,2.85851351 15.0125431,2.41864865 14.8521983,2.05837838 C14.6918534,1.69810811 14.4613578,1.40067568 14.1607112,1.16608108 C13.8600647,0.931486486 13.4932759,0.761824324 13.0603448,0.657094595 C12.6274138,0.552364865 12.1383621,0.5 11.5931897,0.5 L11.5931897,0.5 L8.25,0.5 L8.25,9.8 L10.1741379,9.8 Z M11.1764,4.8524 L9.8,4.8524 L9.8,2.3476 L11.1764,2.3476 C11.7633333,2.3476 12.1973333,2.4406 12.4784,2.6266 C12.7594667,2.8126 12.9,3.13293333 12.9,3.5876 C12.9,4.0588 12.7594667,4.3874 12.4784,4.5734 C12.1973333,4.7594 11.7633333,4.8524 11.1764,4.8524 L11.1764,4.8524 Z M23.975,9.8 L23.975,8.14108108 L19.1780172,8.14108108 L19.1780172,5.82864865 L22.4784483,5.82864865 L22.4784483,4.20743243 L19.1780172,4.20743243 L19.1780172,2.20918919 L23.7344828,2.20918919 L23.7344828,0.5 L17,0.5 L17,9.8 L23.975,9.8 Z M31.975,9.8 L31.975,8.14108108 L27.1780172,8.14108108 L27.1780172,5.82864865 L30.4784483,5.82864865 L30.4784483,4.20743243 L27.1780172,4.20743243 L27.1780172,2.20918919 L31.7344828,2.20918919 L31.7344828,0.5 L25,0.5 L25,9.8 L31.975,9.8 Z" />
          </g>
          <g
            transform="translate(5.000000, 0.000000)"
            strokeLinecap="square"
            strokeWidth="3"
          >
            <polyline points="4.42056539 0.5 0 4.92056539 4.42056539 9.34113079" />
            <path d="M3,5 L16,5 C19.3137085,5 22,7.6862915 22,11 C22,14.3137085 19.3137085,17 16,17 L3,17 L3,17" />
          </g>
        </g>
      </g>
    </svg>
  ),
}

interface Props {
  className?: string
  name: string
  onClick?: () => void
}
export const IconC: FC<Props> = (props) => {
  const { className = '', name, onClick } = props
  return (
    SVGS[name] && (
      <div
        className={`w-7 h-7 inline-flex items-center justify-center ${
          className || ''
        }`}
        onClick={onClick}
      >
        {SVGS[name]}
      </div>
    )
  )
}

export default SVGS
