/* eslint-disable class-methods-use-this */
import IPixel from './IPixel'

export class BingPixel implements IPixel {
  scriptSrc?: string | undefined

  script: string = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"56361901"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`

  noScript: string = ``

  getPfunc() {
    return window.uetq.push
  }

  viewPage(): void {}

  viewProduct(): void {}

  viewCollection(): void {}

  addToCart(): void {}

  placeAnOrder(): void {}

  completePayment(): void {}

  initiateCheckout(): void {}
}

const bingPixel = new BingPixel()
export default bingPixel
