import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'

export const HeaderDataContext = createContext(undefined)

export const StoreProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [headerData, setHeaderData] = useState()
  useEffect(() => {
    // @ts-ignore
    !headerData &&
      fetch('/apis/search?view=menu&q=menu')
        .then((response) => response.json())
        .then((data) => {
          setHeaderData(data)
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
  }, [])

  return (
    <HeaderDataContext.Provider value={headerData}>
      {children}
    </HeaderDataContext.Provider>
  )
}
