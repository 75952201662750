import React, { FC, useRef, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { SwiperOptions, Autoplay } from 'swiper'
import Link from 'next/link'
import 'swiper/css'
import throttle from 'lodash/throttle'
import Trustpilot from '../Trustpilot'
import { GlobalDataContext } from '../../hooks/useGlobalDatas'

SwiperCore.use([Autoplay])
interface Props {
  className?: string
}

const config: SwiperOptions = {
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  observer: true,
  observeParents: true,
  spaceBetween: 0,
  centeredSlides: true,
  slidesPerView: 'auto',
  loop: true,
}

const Discount: FC<Props> = (props) => {
  const { trustpilot } = useContext(GlobalDataContext)
  const { className = '' } = props
  const swiperRef = useRef<any>(null)
  const throttleUpdate = throttle(() => {
    swiperRef?.current?.update?.(true)
  }, 200)
  return (
    <div
      className={`h-10 w-full bg-tag-greybg overflow-hidden relative ${className}`}
    >
      <div className="h-full w-126 box-content  absolute acenter font-semibold md:text-xs flex justify-center">
        {/* 禁止用户滚动 */}
        <Swiper
          {...config}
          onInit={(sw) => {
            swiperRef.current = sw
          }}
          onResize={throttleUpdate}
          className="swiper-no-swiping"
        >
          <SwiperSlide className="w-full">
            <div className="text-white flex items-center justify-center h-10 cursor-pointer text-12">
              <Link href="/pages/return-policy" passHref prefetch={false}>
                <span>Free Returns</span>
              </Link>
              <i className="h-3 w-0.1 bg-white bg-opacity-40 mx-3 md:mx-3.5" />
              <Link href="/pages/shipping-1" passHref prefetch={false}>
                <span>Free Shipping over $59</span>
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-full">
            {/* <Link href="/pages/return-policy" passHref> */}
            <div className="text-white flex items-center justify-center h-10 cursor-pointer text-10">
              {/* <span>Buy 2 Get 15% OFF</span> */}
              <div className="text-center">
                $5 OFF orders <br /> over $39
              </div>
              <i className="h-3 w-0.1 bg-white bg-opacity-40 mx-2.5 md:mx-3.5" />
              {/* <span>Buy 4 Get 20% OFF</span> */}
              <div className="text-center">
                $10 OFF orders <br /> over $69
              </div>
              <i className="h-3 w-0.1 bg-white bg-opacity-40 mx-2.5 md:mx-3.5" />
              {/* <span>Buy 6 Get 25% OFF</span> */}
              <div className="text-center">
                $15 OFF orders <br /> over $99
              </div>
            </div>
            {/* </Link> */}
          </SwiperSlide>
          {trustpilot && trustpilot?.average >= 3.8 && (
            <SwiperSlide className="w-full">
              <div className="text-white flex items-center justify-center h-10 cursor-pointer text-10">
                <Trustpilot
                  count={trustpilot?.count}
                  average={trustpilot?.average}
                  type="forTop"
                />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
  )
}

export default Discount
