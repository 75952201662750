import { useCallback, useEffect, useState, useMemo } from 'react'
import {
  CollectionProductFragment,
  ProductVariantFragmentFragment,
  ProductVariantEdge,
  Image,
} from '../../types/storefront'
import money from '../utils/money'
import { formatVarant, getColorBackground } from '../utils/product'

type VariantMap = Record<string, ProductVariantFragmentFragment[]>

/**
 * 不同颜色按折扣大小进行排序
 */
const sortColorsVariant = (map: VariantMap) => {
  Object.keys(map).forEach((color) => {
    map[color].sort(
      (a, b) =>
        money()
          .calcDiscount(b.priceV2.amount, b.compareAtPriceV2?.amount || 0)
          .value() -
        money(a.priceV2.amount - a.compareAtPriceV2?.amount || 0).value()
    )
  })
}

/**
 * 将颜色折扣组成map
 * @param {VariantMap} variants
 * @returns {Record<string, number[]>}
 */
const getColorDiscount = (variants: VariantMap) => {
  if (variants) {
    const map: Record<string, number[]> = {}
    Object.keys(variants).forEach((i) => {
      const d = variants[i].map((variant) =>
        money()
          .calcDiscount(
            variant.priceV2.amount,
            variant.compareAtPriceV2?.amount
          )
          .value()
      )
      map[i] = d
    })
    return map
    // setDiscountOfColors(map)
  }
  return {}
}

const checkIsNew = (date: string | number) => {
  const d = new Date(date)
  const now = Date.now()
  const INTERVALS = 3 * 24 * 60 * 60 * 1000
  return now - d.getTime() < INTERVALS
}

export default function useProduct(data: CollectionProductFragment) {
  const [isNew, setIsNew] = useState(false)
  const [variantsOfColor, setVariantsOfColor] = useState<VariantMap | null>(
    null
  )
  const [colors, setColors] = useState<string[]>([])
  const [sizes, setSizes] = useState<string[]>([])
  const [variantsOfSize, setVariantsOfSize] = useState<VariantMap>({})
  const [discountOfColors, setDiscountOfColors] = useState<
    Record<string, number[]>
  >({})
  const [selectedColor, setSelectedColor] = useState({
    name: '',
    discount: '' as string | null,
    data: {} as ProductVariantFragmentFragment,
  })

  const imagesMapOfColor = useMemo(() => {
    if (!variantsOfColor) return {}
    return Object.keys(variantsOfColor).reduce<
      Record<string, Image | undefined>
    >((acc, key) => {
      acc[key] = variantsOfColor[key].find(
        (i) => i.image?.originalSrc || i.image?.transformedSrc
      )?.image as Image
      return acc
    }, {})
  }, [variantsOfColor])

  const imageOfSelectColor = useMemo(
    () => imagesMapOfColor[selectedColor.name],
    [imagesMapOfColor, selectedColor]
  )

  const initState = () => {
    const { colorsSet, sizeSet, colorVariants, sizeVariants } = formatVarant(
      data.variants.edges as Array<ProductVariantEdge>
    )

    setColors(Array.from(colorsSet))
    setSizes(Array.from(sizeSet))
    // Sort and setVariants
    sortColorsVariant(colorVariants)
    setVariantsOfColor(colorVariants)

    setIsNew(checkIsNew(data.createdAt))

    const colorDiscounts = getColorDiscount(colorVariants)
    setDiscountOfColors(colorDiscounts)

    setVariantsOfSize(sizeVariants)
  }
  // Run initial
  useEffect(() => {
    initState()
    // eslint-disable-next-line
  }, [])

  /**
   * 从discount数组里获取最大值
   * @param {number[]} discounts
   * @returns {number}
   */
  const getMaxDiscount = (discounts: number[]) => {
    if (!discounts.length) return 0
    return Math.max(...discounts)
  }

  /**
   * 选择颜色variant
   * @param {string} 颜色名
   * @returns {typof selectedColor}
   */
  const selectColor = useCallback(
    (color: string) => {
      if (variantsOfColor) {
        const discount = getMaxDiscount(discountOfColors[color] || [])
        setSelectedColor({
          name: color,
          discount:
            discount && discount > 0 ? money(discount).format('-PRICE%') : null,
          data: variantsOfColor[color]?.[0] || {},
        })
      }
    },
    [discountOfColors, variantsOfColor]
  )

  /**
   * 从颜色数组中获取背景样式
   */
  const colorsWithTagStyle = useCallback(
    () => colors.map((i) => getColorBackground(i)),
    [colors]
  )

  useEffect(() => {
    if (variantsOfColor) {
      selectColor(selectedColor.name || colors[0])
    }
  }, [colors, variantsOfColor, selectedColor.name, selectColor])

  return {
    isNew,
    colors,
    colorsWithTagStyle,
    sizes,
    variantsOfColor,
    variantsOfSize,
    imagesMapOfColor,
    imageOfSelectColor,
    discountOfColors,
    selectColor,
    selectedColor,
  }
}
