import React, {
  FC,
  KeyboardEventHandler,
  FormEventHandler,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react'
// components
import { IoCloseOutline } from 'react-icons/io5'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from '../Image'
import { ProductTitle } from '../Product'
import Container from '../Container'
import Spin from '../Spin'
import { IconC } from '../Icons'
// utils
import money from '../../utils/money'
// types
import { ProductEdge } from '../../../types/storefront'
import { BasePageSensor } from '../../utils/bloomchicdata/page/basepage'
import { useIsomorphicLayoutEffect } from '../../utils/helpers'

const basePageSensorMod = () =>
  import('../../utils/bloomchicdata/page/basepage')

let basePageSensor: BasePageSensor | null
interface Props {
  open?: boolean
  loading?: boolean
  data?: ProductEdge[]
  onChange?: (key: string) => any
  onOpen?: (open: boolean) => any
}

const Search: FC<Props> = ({ onChange, loading, data, open, onOpen }) => {
  const router = useRouter()
  const input = useRef<HTMLInputElement>(null)
  const [visible, setVisible] = useState(false)
  const [key, setKey] = useState('')

  const onInput: FormEventHandler<HTMLInputElement> = (e) => {
    // @ts-ignore
    const { value } = e.target
    setKey(value)
    onChange?.(value)
  }

  useEffect(() => {
    if (router?.query?.q) {
      setKey(router?.query?.q.toString())
    }
  }, [router?.query?.q])

  useEffect(() => {
    if (!router?.query?.q) {
      setKey('')
    }
  }, [router?.asPath])

  useEffect(() => {
    if (open !== visible) {
      setVisible(!!open)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    onOpen?.(visible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (visible) {
      input.current?.focus()
    }
  }, [visible])

  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      basePageSensorMod().then((mod) => {
        basePageSensor = mod.default
      })
    }
  }, [])

  const linkConfig = useMemo(
    () => ({
      pathname: '/search',
      query: {
        type: 'product',
        q: key,
      },
    }),
    [key]
  )

  const handleViewMore = () => {
    setKey('')
    setVisible(false)
    router.push(linkConfig)
  }
  const handleEnter: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      handleViewMore()
      // setVisible(false)
      // router.push(linkConfig)
    }
  }

  const Content = () => {
    if (!key) return null
    if (loading) {
      return (
        <div className="py-40 relative">
          <Spin />
        </div>
      )
    }
    if (data && !data.length) {
      return <div className="text-center py-40">No more result.</div>
    }
    return data ? (
      <div className="text-center pb-6">
        <div className="grid grid-cols-2 md:grid-cols-4">
          {data?.map((i, productIndex) => (
            <Link href={`/products/${i.node.handle}`} passHref>
              <div
                className="px-1.5 mt-5"
                key={i.node.id}
                onClick={() => {
                  setVisible(false)
                  basePageSensor?.ProductClick(
                    i.node,
                    i.node.variants.edges[0].node,
                    productIndex,
                    '搜索结果预览页'
                  )
                }}
              >
                <div
                  className="flex w-full items-start justify-center overflow-hidden relative"
                  style={{ paddingTop: `${(275 / 345) * 100}%` }}
                >
                  <div className="absolute top-0 left-0 w-full h-full">
                    <Image
                      layout="responsive"
                      src={i.node.variants?.edges[0].node.image?.transformedSrc}
                      width={360}
                      height={479}
                    />
                  </div>
                </div>
                <ProductTitle className="my-1">{i.node.title}</ProductTitle>
                <div className="font-semibold">
                  {money(i.node.priceRange?.minVariantPrice.amount).formatPrice(
                    2
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <button
          className="button bg-black text-white text-10 mt-6"
          type="button"
          onClick={handleViewMore}
        >
          VIEW MORE
        </button>
      </div>
    ) : null
  }

  return (
    <div
      className="fixed top-11 bottom-0 w-full z-50"
      style={{ display: visible ? 'block' : 'none' }}
    >
      <div className="relative z-20 bg-white border-b border-white md:mt-8">
        <Container className="flex py-3 items-center h-12 md:h-40 border-b border-gray-200">
          <div onClick={handleViewMore} className="flex items-center">
            {/* <IoSearchOutline
              className="cursor-pointer"
              size={24}
              onClick={() => setVisible(false)}
            /> */}
            <IconC
              name="searchIcon"
              onClick={() => setVisible(false)}
              className="cursor-pointer"
            />
          </div>

          <input
            className="flex-1 mx-2 md:mx-8 p-1 outline-none focus:border-none border-0"
            value={key}
            type="text"
            onInput={onInput}
            placeholder="Search our store"
            onKeyDown={handleEnter}
            ref={input}
            style={{ border: 'none!important' }}
          />
          <button onClick={() => setVisible(false)} type="button">
            <IoCloseOutline size={24} />
          </button>
        </Container>

        <Container className="bg-white relative">
          <Content />
        </Container>
      </div>

      <div
        className="absolute bg-black bg-opacity-0 w-full top-0 bottom-0 z-10"
        onClick={() => setVisible(false)}
      />
    </div>
  )
}

export default Search
