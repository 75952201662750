// noinspection JSDeprecatedSymbols

/**
 * 判断当前设备是否是 iPhone X、iPhone XR、iPhone Xs、iPhone Xs MAX
 *
 * @return   {boolean}          输出 true / false
 */
export const isIphoneXSeries = () =>
  /iphone/gi.test(navigator.userAgent) &&
  window.screen.height >= 812 &&
  window.screen.width >= 375

/**
 * 是否是 PC 环境
 *
 * @return   {boolean} 如果是 PC 环境则返回 true
 */
export const isPC = () => {
  const userAgentInfo = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ]
  let flag = true
  for (let v = 0; v < Agents.length; v += 1) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

/**
 * 是否是 iOS 浏览器内
 *
 * @return   {boolean} 如果是 iOS 环境则返回 true
 */
export const isIOS = () =>
  !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

/**
 * 是否是 iPad 浏览器内
 *
 * @return   {boolean} 如果是 iOS 环境则返回 true
 */
export const isIPad = () => !!navigator.userAgent.match(/ipad/i)

/**
 * 是否是 Android 浏览器内
 *
 * @return   {boolean} 如果是 Android 环境则返回 true
 */
export const isAndroid = () => !!navigator.userAgent.match(/android/i)

/**
 * 是否是钉钉手机客户端浏览器内
 *
 * @return   {boolean} 如果是钉钉环境则返回 true
 */
export const isInDingDing = () => {
  const ua = window.navigator.userAgent
  return !!ua.match(/DingTalk/i)
}

/**
 * 是否是微信浏览器内
 *
 * @return   {boolean} 如果是微信环境则返回 true
 */
export const isInWeixin = () => {
  const ua = window.navigator.userAgent
  return !!ua.match(/MicroMessenger/i)
}

/**
 * 是否在微信小程序内
 *
 * @return   {boolean} 如果是微信环境则返回 true
 */
export const isInWXMiniProgram = () =>
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window.__wxjs_environment && window.__wxjs_environment === 'miniprogram'

/**
 * 是否是在阿里系的 App 内
 *
 * @return boolean
   如果是在阿里系的 App 内则返回 true
 */
export const isInAliApp = () => {
  const ua = window.navigator.userAgent
  return !!ua.match(/AliApp/i)
}

/**
 * 是否是 QQ 浏览器内
 *
 * @return   {boolean} 如果是 QQ 浏览器则返回 true
 */
export const isInQQ = () => !!navigator.userAgent.match(/QQBrowser/i)

/**
 * 是否是微博浏览器内
 *
 * @return   {boolean} 如果是微博浏览器则返回 true
 */
export const isInWeibo = () => !!navigator.userAgent.match(/WeiBo/i)

/**
 * 是否是在Mac系统下运行
 *
 * @return   {boolean} 如果是则返回 true
 */
export const isMac = () => navigator.platform.indexOf('Mac') > -1

/**
 * 是否是在Windows系统下运行
 *
 * @return   {boolean} 如果是则返回 true
 */
export const isWin = () => navigator.platform.indexOf('Win') > -1
