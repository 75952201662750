import React, { useEffect, useState, useMemo, useContext } from 'react'
import Collapse from '@mui/material/Collapse'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  IoChevronUpSharp,
  IoChevronDownSharp,
  // IoMailOutline,
} from 'react-icons/io5'
import classNames from 'classnames'
import { FOOTER_ITEMS } from '../../utils/constants'
import SocialList from '../SocialList'
import Container from '../Container'
import Message from '../Message'
import Button from '../Button'
import SendEmail from '../SendEmail'
import { getEmail } from '../../api/service/user'
import Trustpilot from '../Trustpilot'
import { GlobalDataContext } from '../../hooks/useGlobalDatas'
import Icon from '../Icon'

const Footer = () => {
  const { trustpilot } = useContext(GlobalDataContext)
  const showTrustpilot = useMemo(
    () => trustpilot && trustpilot.average >= 3.8,
    [trustpilot]
  )
  const [footData, setFootData] = useState(FOOTER_ITEMS)
  const [openMail, setOpenMail] = useState<boolean>(false)
  const [subscribe, setSubscribe] = useState({
    status: '' as 'success' | 'error' | '',
    msg: '',
    loading: false,
  })
  const [email, setEmail] = useState(getEmail() || '')
  const [inputDirty, setInputDirty] = useState(false)
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.up('md'))
  const updateState = (index: any) => {
    footData[index].open = !footData[index].open
    setFootData([...footData])
  }

  useEffect(() => {
    setEmail(getEmail() || '')
    setInputDirty(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window !== 'undefined' ? localStorage.getItem('_email') : ''])

  const handleSubscription = async () => {
    if (!email) {
      setSubscribe({
        status: 'error',
        msg: "Email can't be blank.",
        loading: false,
      })
      return
    }

    setSubscribe({
      ...subscribe,
      status: '',
      msg: '',
      loading: true,
    })
    try {
      const res = await fetch('/api/admin/user/create', {
        method: 'POST',
        headers: {
          'Accept-Language': 'en',
        },
        body: JSON.stringify({
          customer: {
            email,
            accepts_marketing: true,
          },
        }),
      })
      const data = await res.json()
      if ([200, 201].includes(res.status) && data.customer.id) {
        setSubscribe({
          ...subscribe,
          status: 'success',
          msg: 'Thanks for subscribing.',
          loading: false,
        })
      }
      if (res.status === 422) {
        setSubscribe({
          ...subscribe,
          status: 'error',
          msg: 'Email not valid or has already been taken.',
          loading: false,
        })
      }
    } catch (e: any) {
      setSubscribe({
        ...subscribe,
        status: 'error',
        loading: false,
      })
    }
  }

  return (
    <Container className="text-xs">
      <div className="md:px-4 md:font-medium md:pt-12">
        {showTrustpilot && trustpilot && (
          <div>
            <Trustpilot
              count={trustpilot?.count}
              average={trustpilot?.average}
              type="forBottom"
            />
          </div>
        )}
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-2 ${
            showTrustpilot ? 'lg:grid-cols-5' : 'lg:grid-cols-4'
          }`}
        >
          {footData.map((i, index) => (
            <div
              className="flex-auto py-4 border-b md:border-none select-none"
              key={i.title + index}
            >
              <div
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  !upSm && updateState(index)
                }}
              >
                <div className="text-14 flex items-center justify-between md:text-gray-400">
                  <span className="text-14 font-semibold">{i.title}</span>
                  {i?.children?.length > 0 && i.open ? (
                    <IoChevronUpSharp className="md:hidden" size="0.9rem" />
                  ) : (
                    <IoChevronDownSharp className="md:hidden" size="0.9rem" />
                  )}
                </div>
              </div>
              {i?.children?.length > 0 && (
                <Collapse
                  className="mt-3"
                  in={upSm || i.open}
                  timeout="auto"
                  unmountOnExit
                >
                  {i.children.map((child, ind) => (
                    <div
                      className={classNames(
                        'c-item py-1.5 transition-all duration-200 z-10 text-left md:text-left text-14 my-1',
                        upSm || i.open
                          ? 'translate-y-0 opacity-100'
                          : 'opacity-0 translate-y-1.5'
                      )}
                      key={ind}
                    >
                      {child.title === 'Contact Us' ? (
                        <span
                          onClick={() => setOpenMail(true)}
                          className="tracking-normal cursor-pointer font-medium"
                        >
                          {child.title}
                        </span>
                      ) : (
                        <a
                          href={child.href}
                          className="tracking-normal font-medium"
                        >
                          {child.title}
                        </a>
                      )}
                    </div>
                  ))}
                </Collapse>
              )}
            </div>
          ))}
          {showTrustpilot && trustpilot && (
            <Trustpilot
              count={trustpilot?.count}
              average={trustpilot?.average}
              type="forFooter"
            />
          )}
          <form
            className="w-full sm:w-3/4 md:w-72 pt-6 sm:pt-5 mx-auto md:mx-0"
            method="post"
          >
            <p className="mb-4 text-center md:text-left break-all leading-5">
              Subscribe to get special offers, free giveaways, and
              once-in-a-lifetime deals.
            </p>
            {subscribe.msg && (
              <Message
                className="mb-3"
                type={subscribe.status as any}
                msg={subscribe.msg}
              />
            )}
            <div className="relative w-3/4 md:w-full mx-auto">
              <input
                value={email}
                className="focus:border-0 focus:border-b-2 border-0 border-b-2 border-black w-full py-2 outline-none placeholder-black font-light pr-10 sm:text-sm text-base"
                type="text"
                name="contact[email]"
                placeholder="Enter your email"
                onChange={(e) => {
                  setEmail(e.target.value)
                  setInputDirty(true)
                }}
                style={{ border: 'none', borderBottom: '2px solid' }}
              />

              <Button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 p-0 tracking-normal"
                onClick={handleSubscription}
                loading={subscribe.loading}
                type="button"
              >
                {email && inputDirty ? (
                  'Subscribe'
                ) : (
                  <Icon name="email" width={28} height={28} />
                  // <IoMailOutline size="24" />
                )}
              </Button>
            </div>

            <SocialList className="mt-4 flex flex-wrap justify-center md:justify-start" />
          </form>
        </div>
      </div>
      <SendEmail open={openMail} onClose={() => setOpenMail(false)} />
    </Container>
  )
}

export default Footer
