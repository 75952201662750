/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, MouseEventHandler } from 'react'
import Link from 'next/link'
import router from 'next/router'
import dayjs from 'dayjs'
import Badge from '../Badge'
import useCart from '../../hooks/useCart'
import Drawer from './Drawer'
import { BasePageSensor } from '../../utils/bloomchicdata/page/basepage'
import Icon from '../Icon'
import { getToken } from '../../api/service/user'
import { useIsomorphicLayoutEffect } from '../../utils/helpers'
import addOrReplaceParam from '../../utils/addQueryParam'
// import Support from '../icon/Support'

const basePageMod = () => import('../../utils/bloomchicdata/page/basepage')

let basePageSensor: BasePageSensor | null
interface Props {
  onSearch: MouseEventHandler
}

export default function Header(props: Props) {
  const { onSearch } = props
  const { show, productCount } = useCart()
  const [drawer, setDrawer] = useState(false)
  const [homeHref, setHomeHref] = useState('/')

  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      basePageMod().then((mod) => {
        basePageSensor = mod.default
      })
    }
  }, [])
  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      if (router.router?.pathname === '/') {
        setHomeHref('/#')
      } else {
        setHomeHref(
          addOrReplaceParam(
            '/',
            'from',
            (router.router?.asPath || '') + dayjs()
          )
        )
      }
    }
  }, [router.router?.asPath])

  return (
    <>
      <div className="flex items-center md:p-2.5 h-12">
        <button className="nav-btn flex items-center md:hidden" type="button">
          <Icon
            name="menu_icon"
            onClick={() => {
              setDrawer(!drawer)
              basePageSensor?.NavigationBarShow()
            }}
          />
        </button>
        <div
          className="search-btn flex-1 px-2.5 py-2 hidden md:block text-2xl cursor-pointer"
          onClick={onSearch}
        >
          {/* <Icon name="search_icon" /> */}
        </div>
        <div className="w-36 md:w-52 leading-0 ml-3 md:ml-0">
          <Link href={homeHref} passHref prefetch={false}>
            <a
              className="logo-btn"
              onClick={() => basePageSensor?.BloomChicClick()}
            >
              <Icon name="logo" height={16.73} width={140} />
              {/* <Image
                src="//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/202172-191715.png?v=1625225502"
                alt="bloomchic logo"
                width={300}
                height={69}
              /> */}
            </a>
          </Link>
        </div>
        <div className="flex-1 flex justify-end text-2xl">
          <button
            className="account-btn mr-4 md:mr-4 flex items-center"
            type="button"
            onClick={() =>
              router.push(getToken() ? '/account' : '/account/login')
            }
          >
            <Icon name="smile_icon" />
          </button>
          <button
            className="search-btn flex items-center"
            type="button"
            onClick={(e) => {
              onSearch(e)
              basePageSensor?.SearchButtonClick()
            }}
          >
            <Icon name="search_icon" />
          </button>
          {/* <button */}
          {/*  className="search-btn ml-4 md:ml-4 flex items-center" */}
          {/*  type="button" */}
          {/*  // @ts-ignore */}
          {/*  onClick={() => {
                // @ts-ignore
                ud('showPanel')
                // @ts-ignore
                $BasePageSensor.ChatClick()
              }} */}
          {/* > */}
          {/*  <Support className="w-4.5" /> */}
          {/* </button> */}
          <button
            className="cart-btn ml-4 md:ml-4 flex items-center relative"
            type="button"
            onClick={() => {
              show()
              basePageSensor?.ShoppingCartClick()
            }}
          >
            <Badge count={productCount || 0}>
              <Icon name="cart_icon" />
            </Badge>
          </button>
        </div>
      </div>

      <Drawer isOpen={drawer} toggleHandler={setDrawer} />
    </>
  )
}
