/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from '@apollo/client'
import { useMemo } from 'react'
import { collectionPolicy } from './policies/collection'
import { productPolicy } from './policies/products'

const uri = `https://${process.env.NEXT_PUBLIC_DOMAIN}/api/${process.env.NEXT_PUBLIC_API_VER}/graphql.json`

// const uri = process.browser
//   ? '/api/graphql'
//   : `https://${process.env.NEXT_PUBLIC_DOMAIN}/api/${process.env.NEXT_PUBLIC_API_VER}/graphql.json`
const headers = {
  'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_TOKEN as string,
}

const httpLink = new HttpLink({
  uri,
  headers,
})

/**
 * Apollo Link —— Like axios interceptors
 * docs: https://www.apollographql.com/docs/react/api/link/introduction/
 */
const listDataLink = new ApolloLink((operation, forward) => forward(operation))
let apolloClient: ApolloClient<any>

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([listDataLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        ...collectionPolicy,
        ...productPolicy,
      },
    }),
  })
}

export default function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [])
  return store
}
