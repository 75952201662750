import React, { useEffect } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function Udesk() {
  const router = useRouter()
  const theme = useTheme()
  const upMd = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    // @ts-ignore
    if (process.browser) {
      if (!upMd && router.route.indexOf('/products') >= 0) {
        document.body?.classList.add('hidden-udesk-btn')
      } else {
        document.body?.classList.remove('hidden-udesk-btn')
      }
    }
  }, [router.route, upMd])

  return (
    <Script strategy="lazyOnload" id="ud-script">
      {`requestIdleCallback(() => {
        (function(a,h,c,b,f,g){a['UdeskApiObject'] = f;a[f]=a[f]||function(){(a[f].d=a[f].d||[]).push(arguments)};g=h.createElement(c);g.async=1;g.charset='utf-8';g.src=b;c=h.getElementsByTagName(c)[0];c.parentNode.insertBefore(g,c)})(window,document,"script","https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js","ud")
        ud({
          code: '144h7fb9',
          link: 'https://1356031.s4.udesk.cn/im_client/?web_plugin_id=25873&language=en-us',
          onReady: function() {
            document.querySelector('.udesk-client-btn').addEventListener('click', () => typeof $BasePageSensor.ChatClick === 'function' ? $BasePageSensor.ChatClick() : null)
          }
        })
      })
      `}
    </Script>
  )
}
