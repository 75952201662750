/* eslint-disable import/prefer-default-export */
export const PRODUCT_SET = [
  {
    name: 'Dresses',
  },
  {
    name: 'Jumpsuits',
  },
  {
    name: 'Matching Sets',
  },
  {
    name: 'Loungewear',
  },
  {
    name: 'Intimates',
  },
  {
    name: 'Bodysuits',
  },
  {
    name: 'Sweatshirts',
    children: [
      {
        name: 'Sweatshirts',
        parent: 'Sweatshirts',
      },
      {
        name: 'Sweatshirt Dresses',
        parent: 'Sweatshirts',
      },
    ],
    childrenStr: 'Sweatshirts+Sweatshirt Dresses',
  },
  {
    name: 'Blazers',
  },
  {
    name: 'Tops T-shirts',
    children: [
      {
        name: 'Blouses',
        parent: 'Tops T-shirts',
      },
      {
        name: 'Tank Tops & Camis',
        parent: 'Tops T-shirts',
      },
    ],
    childrenStr: 'Blouses+Tank Tops & Camis',
  },
  {
    name: 'Bottoms Skirts',
    children: [
      {
        name: 'Pants',
        parent: 'Bottoms Skirts',
      },
      {
        name: 'Shorts',
        parent: 'Bottoms Skirts',
      },
      {
        name: 'Leggings',
        parent: 'Bottoms Skirts',
      },
    ],
    childrenStr: 'Pants+Shorts+Leggings',
  },
  {
    name: 'Sweaters & Cardigans',
    children: [
      {
        name: 'Sweaters',
        parent: 'Sweaters & Cardigans',
      },
      {
        name: 'Knit Tops',
        parent: 'Sweaters & Cardigans',
      },
      {
        name: 'Sweater Dresses',
        parent: 'Sweaters & Cardigans',
      },
      {
        name: 'Cardigans',
        parent: 'Sweaters & Cardigans',
      },
      {
        name: 'Knit Skirts',
        parent: 'Sweaters & Cardigans',
      },
      {
        name: 'Knit Pants',
        parent: 'Sweaters & Cardigans',
      },
      {
        name: 'Knit Matching Sets',
        parent: 'Sweaters & Cardigans',
      },
    ],
    childrenStr:
      'Sweaters+Knit Tops+Sweater Dresses+Cardigans+Knit Skirts+Knit Pants+Knit Matching Sets',
  },
  {
    name: 'Swimwear',
    children: [
      {
        name: 'Bikinis',
        parent: 'Swimwear',
      },
      {
        name: 'One-Pieces',
        parent: 'Swimwear',
      },
      {
        name: 'Tankinis',
        parent: 'Swimwear',
      },
    ],
    childrenStr: 'Bikinis+One-Pieces+Tankinis',
  },
  {
    name: 'Cover Ups & Kimonos',
    children: [
      {
        name: 'Kimonos',
        parent: 'Cover Ups & Kimonos',
      },
      {
        name: 'Cover Ups',
        parent: 'Cover Ups & Kimonos',
      },
    ],
    childrenStr: 'Kimonos+Cover Ups',
  },
  {
    name: 'Lingerie',
    children: [
      {
        name: 'Bras & Bralettes',
        parent: 'Lingerie',
      },
      {
        name: 'Bra & Panty Sets',
        parent: 'Lingerie',
      },
      {
        name: 'Corsets & Shapewear',
        parent: 'Lingerie',
      },
      {
        name: 'Sexy Lingerie',
        parent: 'Lingerie',
      },
    ],
    childrenStr:
      'Bras & Bralettes+Bra & Panty Sets+Corsets & Shapewear+Sexy Lingerie',
  },
  {
    name: 'Denim',
    children: [
      {
        name: 'Jeans',
        parent: 'Denim',
      },
      {
        name: 'Denim Dresses',
        parent: 'Denim',
      },
      {
        name: 'Denim Jackets',
        parent: 'Denim',
      },
      {
        name: 'Denim Shorts',
        parent: 'Denim',
      },
      {
        name: 'Denim Skirts',
        parent: 'Denim',
      },
      {
        name: 'Denim Tops',
        parent: 'Denim',
      },
      {
        name: 'Denim Sets',
        parent: 'Denim',
      },
      {
        name: 'Denim Overalls',
        parent: 'Denim',
      },
    ],
    childrenStr:
      'Jeans+Denim Dresses+Denim Jackets+Denim Shorts+Denim Skirts+Denim Tops+Denim Sets+Denim Overalls',
  },
  {
    name: 'Coats & Jackets',
    children: [
      {
        name: 'Coats',
        parent: 'Coats & Jackets',
      },
      {
        name: 'Jackets',
        parent: 'Coats & Jackets',
      },
      {
        name: 'Cotton Jackets',
        parent: 'Coats & Jackets',
      },
      {
        name: 'Trench Coats',
        parent: 'Coats & Jackets',
      },
    ],
    childrenStr: 'Coats+Jackets+Cotton Jackets+Trench Coats',
  },
  {
    name: 'Accessories',
    children: [
      {
        name: 'Glasses & Eyewear Accessories',
        parent: 'Accessories',
      },
      {
        name: 'Hats & Gloves',
        parent: 'Accessories',
      },
      {
        name: 'Hair Accessories',
        parent: 'Accessories',
      },
      {
        name: 'Scarves',
        parent: 'Accessories',
      },
      {
        name: 'Belts',
        parent: 'Accessories',
      },
      {
        name: 'Socks & Tights',
        parent: 'Accessories',
      },
      {
        name: 'Keychains',
        parent: 'Accessories',
      },
      {
        name: 'Watches',
        parent: 'Accessories',
      },
      {
        name: 'Umbrellas',
        parent: 'Accessories',
      },
      {
        name: 'Face Masks',
        parent: 'Accessories',
      },
    ],
    childrenStr:
      'Glasses & Eyewear Accessories+Hats & Gloves+Hair Accessories+Scarves+Belts+Socks & Tights+Keychains+Watches+Umbrellas+Face Masks',
  },
  {
    name: 'Jewelry',
    children: [
      {
        name: 'Necklaces',
        parent: 'Jewelry',
      },
      {
        name: 'Sets',
        parent: 'Jewelry',
      },
      {
        name: 'Earrings',
        parent: 'Jewelry',
      },
      {
        name: 'Bracelets',
        parent: 'Jewelry',
      },
      {
        name: 'Rings',
        parent: 'Jewelry',
      },
      {
        name: 'Brooches',
        parent: 'Jewelry',
      },
      {
        name: 'Body Jewelry',
        parent: 'Jewelry',
      },
    ],
    childrenStr:
      'Necklaces+Sets+Earrings+Bracelets+Rings+Brooches+Body Jewelry',
  },
  {
    name: 'Shoes',
    children: [
      {
        name: 'Boots',
        parent: 'Shoes',
      },
      {
        name: 'Wedges',
        parent: 'Shoes',
      },
      {
        name: 'Heels',
        parent: 'Shoes',
      },
      {
        name: 'Sandals',
        parent: 'Shoes',
      },
      {
        name: 'Flats',
        parent: 'Shoes',
      },
      {
        name: 'Sneakers',
        parent: 'Shoes',
      },
      {
        name: 'Slippers',
        parent: 'Shoes',
      },
    ],
    childrenStr: 'Boots+Wedges+Heels+Sandals+Flats+Sneakers+Slippers',
  },
  {
    name: 'Women Bags',
    children: [
      {
        name: 'Shoulder & Tote Bag',
        parent: 'Women Bags',
      },
      {
        name: 'Backpack',
        parent: 'Women Bags',
      },
      {
        name: 'Crossbody Bags',
        parent: 'Women Bags',
      },
      {
        name: 'Evening Bags & Clutches',
        parent: 'Women Bags',
      },
      {
        name: 'Purses',
        parent: 'Women Bags',
      },
      {
        name: 'Satchels',
        parent: 'Women Bags',
      },
      {
        name: 'Belt Bags',
        parent: 'Women Bags',
      },
      {
        name: 'Handbag Sets',
        parent: 'Women Bags',
      },
      {
        name: 'Handbag Accessories',
        parent: 'Women Bags',
      },
    ],
    childrenStr:
      'Shoulder & Tote Bag+Backpack+Crossbody Bags+Evening Bags & Clutches+Purses+Satchels+Belt Bags+Handbag Sets+Handbag Accessories',
  },
]
