/** Server config */
const config = {
  multipass_secret: process.env.MULTIPASS_SECRET,
  api_url:
    process.env.NEXT_PUBLIC_APP_ENV === 'prod'
      ? 'https://nyfevdqd2j.execute-api.us-east-1.amazonaws.com/'
      : 'https://dqovn51u6b.execute-api.us-east-1.amazonaws.com/',
}

export default config
