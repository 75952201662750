import { Connectives, ProductFilterKeys } from '../../types/product'
import { ProductFilter } from '../../types/storefront'
import {
  COLOR_FILTERS_SET,
  SIZE_FILTER_SET,
  FilterObject,
  PRICE_FILTER_SET,
} from './constants/filters'

const SPLIT_TAG = '-condition-'

export const RuleRelations = {
  equals: ':',
  not_equals: '-',
  greater_than: ':>',
  less_than: ':<',
}

export const QueryRuleColumn = {
  tag: 'tag',
  type: 'product_type',
  variant_price: 'variant.price',
  title: 'title',
  vendor: 'vendor',
  variant_inventory: 'available_for_sale',
}

export interface CollectionRule {
  column: keyof typeof QueryRuleColumn
  relation: keyof typeof RuleRelations
  condition: string
}

export interface CollectionCondition {
  disjunctive: 0 | 1
  rules: CollectionRule[]
}

/**
 * Get collection rule setting object
 */
export function splitQuery(query: string): CollectionCondition | undefined {
  try {
    const s = query.split(SPLIT_TAG)
    const setting = JSON.parse(s[s.length - 1] || '{}')
    return setting
  } catch (e) {
    return undefined
  }
}

export function conditionToQuery(condition: CollectionCondition) {
  const querys = condition.rules.map((i) => {
    if (i.relation === 'not_equals') {
      return `-${QueryRuleColumn[i.column]}${RuleRelations.equals}${
        i.condition
      }`.toLowerCase()
    }

    /** Product inventory */
    if (i.column === 'variant_inventory') {
      return !Number(i.condition)
        ? QueryRuleColumn[i.column]
        : `-${QueryRuleColumn[i.column]}`
    }

    return `${QueryRuleColumn[i.column]}${RuleRelations[i.relation]}${
      i.condition
    }`.toLowerCase()
  })
  return querys.join(` ${condition.disjunctive === 0 ? 'AND' : 'OR'} `)
}

export function getCollectionQuery(data?: string) {
  if (!data) return ''

  const condition = splitQuery(data)
  if (!condition) return ''

  const query = conditionToQuery(condition)
  return query
}

/**
 * Map filters to shopify query syntax
 * https://shopify.dev/api/usage/search-syntax
 * 1. common useage eg:
 * Find a skirts type
 * query: product_type: skirts
 *
 * 2. multiple query use space as spliter eg:
 * query product_type = skirt and tag = black
 * query: product_type: skirt tag: black
 * @returns {string} query
 */
export function mapFiltersToQuery(
  filter?: ProductFilter,
  connective?: Connectives
) {
  if (!filter) return ''

  const query = Object.keys(filter).reduce<string[]>((acc, key) => {
    const data = filter[key as keyof ProductFilter]
    let value = ''
    if (typeof data === 'string') {
      value = `${key}:${data}`
    }

    if (Array.isArray(data)) {
      value = `${key}:${data.join(' AND ')}`
    }

    if (typeof data === 'object') {
      if (data && 'min' in data) {
        value = `${key}:>${data.min}`
      }
      if (data && 'max' in data) {
        value += ` ${key}:<${data.max}`
      }
    }

    acc.push(value)
    return acc
  }, [])

  return `${query.join(` ${connective} ` || ' ')}`
}

/**
 * Parse Product to router query
 */
const FILTER_SPILITER = '+'
export const combineFilters = (productFilters: FilterObject[]) => {
  const querys = productFilters.reduce<Record<string, string[]>>(
    (acc, item) => {
      // Object.keys(item).forEach(() => {
      // const isVariant = key === 'variantOption'
      const queryKey = item.type
      // Initial Array
      if (!acc[queryKey]) {
        acc[queryKey] = []
      }

      // const i = (item as any)[key]
      // acc[queryKey].push(encodeURI(getValue(key, i)))
      acc[queryKey].push(item.label)
      // })

      return acc
    },
    {}
  )
  return Object.keys(querys).reduce<Record<string, string>>((acc, key) => {
    acc[key] = querys[key].join(FILTER_SPILITER)
    return acc
  }, {})
}

/**
 * Parse route query to `ProductFilter`
 */
export const parseQueryToFilter = (
  query: Record<string, string | string[] | undefined>,
  ...extraData: FilterObject[]
): FilterObject[] => {
  const types = [
    ProductFilterKeys.type,
    ProductFilterKeys.color,
    ProductFilterKeys.size,
    ProductFilterKeys.price,
    ProductFilterKeys.vendor,
  ]

  const filters = Object.keys(query).reduce<FilterObject[]>((acc, key) => {
    if (types.includes(key as any)) {
      const item = query[key]
      if (typeof item !== 'string') return acc

      item.split(FILTER_SPILITER).forEach((value) => {
        const label = decodeURI(value)
        const filter = [
          ...COLOR_FILTERS_SET,
          ...PRICE_FILTER_SET,
          ...SIZE_FILTER_SET,
          ...extraData,
        ]
          .flat()
          .find((i) => i.label.toLowerCase() === label.toLowerCase())
        if (filter) {
          acc.push(filter)
        }
        // return acc
      })
    }
    return acc
  }, [])

  return filters
}

export const parseQueryToFilterParams = (
  query: Record<string, string | string[] | undefined>
) => {
  const types = [
    ProductFilterKeys.type,
    ProductFilterKeys.color,
    ProductFilterKeys.size,
    ProductFilterKeys.price,
    ProductFilterKeys.vendor,
  ]
  const filters = Object.keys(query).reduce<ProductFilter[]>((acc, key) => {
    if (types.includes(key as any)) {
      const item = query[key]
      if (typeof item !== 'string') return acc

      item.split(FILTER_SPILITER).forEach((value) => {
        const v = decodeURI(value)
        if (
          [ProductFilterKeys.color, ProductFilterKeys.size].includes(key as any)
        ) {
          acc.push({
            variantOption: {
              name: key,
              value: v,
            },
          })
        } else if (ProductFilterKeys.type === key) {
          acc.push({
            productType: v,
          })
        }
      })
    }
    return acc
  }, [])
  return filters
}

/**
 *
 * @param key - search Key
 * @returns {string} shopify search syntax
 */
export function getSearchQuery(key: string) {
  const search = key?.split(' ')?.join('" * "') ?? ''
  return `"* ${search}" * OR tag:"${key}"`
}
