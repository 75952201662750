/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import cloneDeep from 'lodash/cloneDeep'
import Link from 'next/link'
import {
  IoChevronDownSharp,
  IoChevronUpSharp,
  IoCloseOutline,
} from 'react-icons/io5'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@mui/material'
import SocialList from '../SocialList'
import { BasePageSensor } from '../../utils/bloomchicdata/page/basepage'
import { HeaderDataContext } from '../../hooks/useHeaderData'
import { getToken } from '../../api/service/user'
import { useIsomorphicLayoutEffect } from '../../utils/helpers'

const basePageMod = () => import('../../utils/bloomchicdata/page/basepage')

let basePageSensor: BasePageSensor | null
interface NavData {
  open: boolean
  id: string
  title: string
  sub_links: Array<NavData>
  url?: string
}

const list = (
  isOpen: boolean,
  toggleHandler: any,
  navData: Array<NavData> | undefined,
  updateState: any,
  token: string
) => (
  <div className="w-72">
    {navData &&
      listTree(isOpen, toggleHandler, navData, navData, updateState, 1, [])}
    <div className=" h-2.5 bg-tag-grayf5 relative z-10 -mt-2.5" />
    <Link href={`/account${token ? '' : '/login'}`} passHref prefetch={false}>
      <button
        className="text-13 font-medium px-4 mt-4 mb-3"
        onClick={() => {
          toggleHandler(false)
          basePageSensor?.NavigationBarClick([token ? 'Account' : 'Log In'])
        }}
        type="button"
      >
        {token ? 'Account' : 'Log In'}
      </button>
    </Link>

    <SocialList
      className="pb-6 px-4"
      iconClass="box-border w-1/3 h-9 px-2 py-2 mt-0 -mr-px -mb-px ml-0 border border-gray-200"
    />
  </div>
)

const listTree: (
  isOpen: boolean,
  toggleHandler: any,
  currentData: NavData[],
  navData: NavData[],
  updateState: any,
  level: number,
  titleArray: string[]
) => JSX.Element = (
  isOpen: boolean,
  toggleHandler: any,
  currentData: NavData[],
  navData: NavData[],
  updateState: any,
  level: number,
  titleArray: string[]
) => (
  <List>
    {currentData?.map(({ title, sub_links, url, open, id }, index, self) => {
      const temptitleArray = [...titleArray]
      temptitleArray.push(title)
      return (
        <Box
          className={classNames({
            'border-b border-tag-graye9': id.indexOf('.') < 0,
          })}
          key={`listTree${id}`}
          sx={{
            '& .MuiList-root': {
              pl: 2,
            },
          }}
        >
          <ListItem
            button
            onClick={() => toggleHandler(!isOpen)}
            secondaryAction={
              sub_links &&
              sub_links.length > 0 && (
                <div
                  className="px-4 py-1.5"
                  onClick={(e) => {
                    e.stopPropagation()
                    updateState(self[index], navData)
                  }}
                >
                  {!open ? (
                    <IoChevronDownSharp size="0.9rem" />
                  ) : (
                    <IoChevronUpSharp size="0.9rem" />
                  )}
                </div>
              )
            }
            sx={{
              height: id.indexOf('.') < 0 ? '52px' : '36px',
              borderLeft:
                id.split('.').length - 1 > 1 ? '1px solid #000' : 'none',
              backgroundColor: '#00000',
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              '& .MuiListItemSecondaryAction-root': {
                right: '0',
              },
              '&:hover': {
                background: 'none',
              },
            }}
          >
            <ListItemText
              className="pl-4"
              primary={
                <Link href={url ? `${url}` : '###'} passHref prefetch={false}>
                  <button
                    className={classNames(
                      'navItem w-full text-left font-sans ',
                      {
                        'text-xs tracking-normal font-normal':
                          id.indexOf('.') >= 0,
                      },
                      {
                        'text-sm font-medium': id.indexOf('.') < 0,
                      }
                    )}
                    onClick={() =>
                      basePageSensor?.NavigationBarClick(temptitleArray)
                    }
                    type="button"
                  >
                    {title}
                  </button>
                </Link>
              }
            />
          </ListItem>
          {sub_links && sub_links.length > 0 && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {listTree(
                isOpen,
                toggleHandler,
                sub_links,
                navData,
                updateState,
                level + 1,
                temptitleArray
              )}
            </Collapse>
          )}
        </Box>
      )
    })}
  </List>
)

// 格式化数据，加上目录id
const cacheIndex: number[] = [0]
function formatData(data: NavData[]) {
  const res: NavData[] = data
  res.forEach((i: NavData) => {
    // @ts-ignore
    cacheIndex.push(cacheIndex?.pop() + 1)
    i.open = false
    i.sub_links = i.sub_links || []
    i.id = cacheIndex.join('.')
    if (i.sub_links) {
      cacheIndex.push(0)
      formatData(i.sub_links)
      cacheIndex.pop()
    }
  })
  return res
}

const navDrawer: FC<PropsWithChildren<any>> = React.memo(
  ({ isOpen, toggleHandler }) => {
    const [navData, setNavData] = useState<Array<NavData>>()
    const [token, setToken] = useState<string>('')
    const headerData = useContext(HeaderDataContext)
    const router = useRouter()

    useEffect(() => {
      setToken(getToken() || '')
    }, [router.asPath])

    useEffect(() => {
      // @ts-ignore
      if (headerData?.sub_links?.length) {
        // @ts-ignore
        const res = formatData(cloneDeep(headerData.sub_links))
        setNavData(res)
      }
    }, [headerData])

    useIsomorphicLayoutEffect(() => {
      if (process.browser) {
        basePageMod().then((mod) => {
          // eslint-disable-next-line new-cap
          basePageSensor = mod.default
        })
      }
    }, [])

    // 查询目录id，更新listTree数据
    const updateState = (
      item: { id: string; open: any },
      navData: NavData[]
    ) => {
      const idArray = item.id.split('.')
      const [...items] = navData

      const findItem = (index: number | undefined, children: any[]) => {
        const id = idArray.slice(0, index).join('.')
        const foundItem = children.find((item) => item.id === id)
        if (index === idArray.length) {
          foundItem.open = !item.open
          setNavData(items)
        } else {
          // @ts-ignore
          findItem(index + 1, foundItem.sub_links)
        }
      }
      findItem(1, items)
    }

    return (
      <Drawer
        open={isOpen}
        onClose={() => toggleHandler(!isOpen)}
        className="navDrawer"
      >
        <div
          className="flex justify-end text-2xl px-3 pt-5 pb-1"
          onClick={() => toggleHandler(false)}
        >
          <IoCloseOutline />
        </div>
        {list(isOpen, toggleHandler, navData, updateState, token)}
      </Drawer>
    )
  }
)
export default navDrawer
