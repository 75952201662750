import React, { FC, PropsWithChildren } from 'react'

interface Props {
  className?: string
  [key: string]: any
}

const Container: FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, ...others } = props
  return (
    <div className={`lg:container px-3 md:px-5 ${className}`} {...others}>
      {children}
    </div>
  )
}

export default Container
