/* eslint-disable class-methods-use-this */
import IPixel, { IPixelProduct } from './IPixel'

export class FacebooPixel implements IPixel {
  script: string = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '763649524226560');`

  noScript: string = `<img height="1" width="1" style="display:none" alt=""
  src="https://www.facebook.com/tr?id=763649524226560&ev=PageView&noscript=1"/>`

  getPfunc(): any {
    return window.fbq
  }

  viewPage(): void {
    this.getPfunc?.()('track', 'PageView')
    if (window.location.pathname.split('/')[1] === 'collections') {
      this.viewCollection()
    }
  }

  viewProduct(product: IPixelProduct): void {
    const data = {
      content_type: 'product_group',
      content_ids: [product.id],
      value: product.value,
      content_name: product.name,
      currency: product.currency,
      content_category: product.category,
    }
    this.getPfunc?.()('track', 'ViewContent', data)
  }

  viewCollection(): void {}

  addToCart(product: IPixelProduct): void {
    const data = {
      content_type: 'product_group',
      content_ids: [product.id],
      value: product.value,
      num_items: 1,
      content_name: product.name,
      currency: product.currency,
      content_category: product.category,
    }
    this.getPfunc?.()('track', 'AddToCart', data)
  }

  placeAnOrder(): void {}

  completePayment(): void {}

  initiateCheckout(): void {}
}

// const isServer = !process.browser

const facebookPixel = new FacebooPixel()

export default facebookPixel
