/* eslint-disable class-methods-use-this */
import IPixel, { IPixelProduct } from './IPixel'

const TAG_ID = 'AW-444369709'

export class GooglePixel implements IPixel {
  //   script: string = `var loadScript=function(url,cb){var script=document.createElement("script");script.src=url;var firstScript=document.getElementsByTagName("script")[0];firstScript.parentNode.insertBefore(script,firstScript);script.onload=script.onreadystatechange=function(){if(!this.readyState||this.readyState=="loaded"||this.readyState=="complete"){cb&&cb()}}};
  //   loadScript('https://www.googletagmanager.com/gtag/js?id=AW-444369709',
  //     function(){
  //         window.dataLayer = window.dataLayer || [];
  //         function gtag(){dataLayer.push(arguments);}
  //         gtag('js', new Date());
  //         gtag('config', 'AW-444369709', {'linker': {'domains': ['bloomchic.com']}});
  //         window.gtag = gtag
  //     }
  // )`
  scriptSrc = `https://www.googletagmanager.com/gtag/js?id=${TAG_ID}`

  script: string = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${TAG_ID}', {'linker': {'domains': ['bloomchic.com']}});
  `

  noScript: string = ``

  getPfunc() {
    return window.gtag
  }

  viewPage(): void {
    if (window.location.pathname.split('/')[1] === 'collections') {
      this.viewCollection()
    }
    this.getPfunc?.()('event', 'PageView')
  }

  viewProduct(product: IPixelProduct): void {
    this.getPfunc?.()('event', 'ViewProduct', {
      Category: product.category,
      Action: 'ViewedProduct',
    })
  }

  viewCollection(): void {
    this.getPfunc?.()('event', 'view_item_list', {
      Category: window.location.pathname.split('/')[2],
      Action: 'ViewedCollection',
    })
  }

  addToCart(product: IPixelProduct): void {
    this.getPfunc?.()('event', 'AddToCart', {
      Category: product.category,
      Action: 'AddedProduct',
    })
  }

  placeAnOrder(): void {}

  completePayment(): void {}

  initiateCheckout(): void {}
}

const googlePixel = new GooglePixel()

export default googlePixel
