import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import {
  ProductConnectionQuery,
  ProductConnectionQueryVariables,
} from '../../types/storefront'
import { ProductConnection } from '../api/graphql/productsQuery.gql'
import { Connectives, ProductFilter } from '../../types/product'
import { getCursors } from '../utils/helpers'
import { mapFiltersToQuery } from '../utils/query-parser'
import { ProductSortList } from '../utils/constants'

export default function useProductsQuery() {
  const [variables, setVariables] = useState<ProductConnectionQueryVariables>({
    first: 4,
  })

  const [
    getProducts,
    { data, loading, fetchMore: queryMore, refetch, networkStatus },
  ] = useLazyQuery<ProductConnectionQuery, ProductConnectionQueryVariables>(
    ProductConnection
  )

  const fetch = (options: {
    query?: string
    filter?: ProductFilter
    variables: ProductConnectionQueryVariables
    connective?: Connectives
  }) => {
    const query =
      options.query || mapFiltersToQuery(options.filter, options.connective)

    if (options.variables.after) {
      return queryMore?.({
        variables: {
          ...options.variables,
          query,
        },
      })
    }

    return getProducts({
      variables: {
        ...options.variables,
        query,
      },
    })
  }

  const fetchMore = (options: {
    query?: string
    filter?: ProductFilter
    variables: ProductConnectionQueryVariables
    connective?: Connectives
  }) => {
    if (!data?.products.pageInfo.hasNextPage) return Promise.reject()

    const { filter, variables: vars, connective } = options
    const cursor = getCursors(data.products.edges)
    const params: ProductConnectionQueryVariables = {
      ...vars,
      after: cursor,
    }

    return fetch({
      connective,
      filter,
      query: options.query,
      variables: params,
    })
  }

  const fetchWithSort = (
    searchValue: string,
    vars?: ProductConnectionQueryVariables
  ) => {
    const item = ProductSortList.find((i) => i.searchValue === searchValue)
    const v: any = {
      ...variables,
      ...vars,
      sortKey: item?.value,
    }

    if (item?.reverse) v.reverse = item.reverse

    refetch?.({
      ...v,
    })
  }

  return {
    fetch,
    refetch,
    networkStatus,
    setVariables,
    variables,
    data,
    loading,
    fetchMore,
    getProducts,
    queryMore,
    fetchWithSort,
  }
}
