/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren, FC, MouseEventHandler } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'

interface CenteredContainerProps {
  tagName?: string
  className?: string
  onClick?: MouseEventHandler
  type?: string
}

interface ButtonProps {
  loading?: boolean
  disabled?: boolean
}

const CenteredContainer: FC<PropsWithChildren<CenteredContainerProps>> = ({
  tagName,
  ...otherProps
}) => {
  const Tag = tagName as keyof JSX.IntrinsicElements
  // @ts-ignore
  return <Tag {...otherProps} />
}

const Spin = () => (
  <span className="animate-spin inline-block">
    <AiOutlineLoading />
  </span>
)

const Button: FC<PropsWithChildren<CenteredContainerProps & ButtonProps>> = (
  props
) => {
  const {
    tagName = 'button',
    children,
    loading,
    className,
    ...otherProps
  } = props

  return (
    <CenteredContainer
      tagName={tagName}
      className={`button ${className}`}
      {...otherProps}
    >
      {loading ? <Spin /> : children}
    </CenteredContainer>
  )
}
export default Button
