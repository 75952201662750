const addOrReplaceParam = (url: string, key: string, val: string | number) => {
  const newParam = `${key}=${encodeURIComponent(val)}`
  let params = `?${newParam}`
  // If the "url" string exists, then build params from it
  if (url) {
    // Try to replace an existence instance
    params = url.replace(new RegExp(`([?&])${key}[^&]*`), `$1${newParam}`)
    // If nothing was replaced, then add the new param to the end
    if (params === url && url.indexOf(newParam) < 0) {
      params += (url.indexOf('?') > 0 ? '&' : '?') + newParam
    }
  }
  return params
}
export default addOrReplaceParam
