/* eslint-disable import/prefer-default-export */
import { TypePolicies } from '@apollo/client'

export const productPolicy: TypePolicies = {
  Query: {
    fields: {
      products: {
        keyArgs: ['query'],
        merge(existing, incoming, { args }) {
          if (!existing || !args?.after) return incoming
          // return [...existing, ...incoming]
          return {
            ...incoming,
            edges: [...existing.edges, ...incoming.edges],
          }
        },
      },
    },
  },
}
