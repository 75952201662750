import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  useMemo,
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import {
  cartCreate,
  cartLinesUpdate,
  cartLinesAdd,
  cartLinesRemove,
} from '../api/graphql/cartActions.gql'
import { checkoutURL } from '../api/graphql/cartQuery.gql'
import { CART_ID, CART_ITEMS } from '../utils/constants'

import {
  CartCreateMutation,
  CartCreateMutationVariables,
  CartFragmentFragment,
  CartLineInput,
  CartLinesAddMutation,
  CartLinesAddMutationVariables,
  CartLinesRemoveMutation,
  CartLinesRemoveMutationVariables,
  CartLinesUpdateMutation,
  CartLinesUpdateMutationVariables,
  CartLineUpdateInput,
  ProductVariant,
} from '../../types/storefront'
import { CartAction } from '../../types/cart'
import { getEmail, getToken } from '../api/service/user'

const persistCart = (data: Partial<CartFragmentFragment>) => {
  try {
    localStorage.setItem(CART_ITEMS, JSON.stringify(data))
  } catch (e) {
    console.error(`Persist cart data error: ${e}`)
  }
}

const getPersistItems = () => {
  try {
    return JSON.parse(localStorage.getItem(CART_ITEMS) || '[]')
  } catch (e) {
    console.error('Get persist cart data error: ', e)
    return []
  }
}

/**
 * Sort cart item by add time
 * @param origin old cart data
 * @param income income cart data
 * @returns
 */
const sortLines = (
  origin: CartFragmentFragment['lines'],
  income: CartFragmentFragment['lines']
) => {
  if (!origin?.edges || !income?.edges) return income
  const incomeMap = income.edges.reduce<Record<string, any>>((acc, i) => {
    acc[i.node.id] = i
    return acc
  }, {})

  /** Update old data */
  const newArray = origin.edges.map((i) => incomeMap[i.node.id]).filter(Boolean)

  const map: any = {}
  newArray.forEach((i) => {
    map[i.node.id] = i
  })
  // Find new income data
  const newItem = income.edges.filter((i) => !map[i.node.id])

  // Return updated list if havn't new item
  if (!newItem.length) {
    return {
      ...income,
      edges: newArray,
    }
  }

  // Shift new item
  return {
    ...income,
    edges: [...newItem, ...origin.edges],
  }
}

/**
 * Initial data and cart reducer
 */
const cartData = {
  ...((process.browser ? getPersistItems() : {}) as CartFragmentFragment),
  open: false,
}
function reducer(state: typeof cartData, action: CartAction) {
  switch (action.type) {
    case 'toggle':
      return { ...state, open: action.open }
    case 'create':
    case 'add_item':
    case 'update_quantity':
      return {
        ...state,
        ...action.data,
        lines: sortLines(state.lines, action.data?.lines),
      }
    default:
      return state
  }
}
export const CartContext = createContext<{
  state: typeof cartData
  dispatch: Dispatch<CartAction>
}>({} as any)

export const CartProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, cartData)

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  )
}

/**
 * Cart hooks
 * Create / Add Lines / Delete Lines / Update quantity
 */
const useCart = () => {
  const router = useRouter()
  const { state, dispatch } = useContext(CartContext)
  /**
   * Create Mutation
   */
  const [create, createRes] = useMutation<
    CartCreateMutation,
    CartCreateMutationVariables
  >(cartCreate, {
    onCompleted(data) {
      dispatch({
        type: 'create',
        data: data?.cartCreate?.cart as CartFragmentFragment,
      })

      if (data.cartCreate?.cart?.id) {
        try {
          localStorage.setItem(CART_ID, data.cartCreate.cart.id)
        } catch (e) {
          console.error(`Save cart id error: ${e}`)
        }
      }
    },
  })

  /**
   * Add Mutation
   */
  const [cartAdd, addRes] = useMutation<
    CartLinesAddMutation,
    CartLinesAddMutationVariables
  >(cartLinesAdd, {
    onCompleted(data) {
      dispatch({
        type: 'add_item',
        data: data?.cartLinesAdd?.cart as CartFragmentFragment,
      })
    },
  })

  /**
   * Update Mutation
   */
  const [updateCart, updateRes] = useMutation<
    CartLinesUpdateMutation,
    CartLinesUpdateMutationVariables
  >(cartLinesUpdate, {
    onCompleted(data) {
      dispatch({
        type: 'update_quantity',
        data: data?.cartLinesUpdate?.cart as CartFragmentFragment,
      })
    },
  })

  /**
   * Remove Mutation
   */
  const [removeItem, removeRes] = useMutation<
    CartLinesRemoveMutation,
    CartLinesRemoveMutationVariables
  >(cartLinesRemove, {
    onCompleted(data) {
      dispatch({
        type: 'update_quantity',
        data: data?.cartLinesRemove?.cart as CartFragmentFragment,
      })
    },
  })

  const checkout = useQuery(checkoutURL, {
    skip: true,
  })
  const loading = useMemo<boolean>(
    () =>
      [createRes, updateRes, addRes, removeRes, checkout].some(
        (i) => i.loading
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createRes.loading,
      updateRes.loading,
      addRes.loading,
      removeRes.loading,
      checkout.loading,
    ]
  )

  /**
   * Update cart items
   * @param {string} id product id
   * @param {number} quantity product number
   */
  const update = (id: string, quantity: number) => {
    const {
      lines: { edges },
    } = state
    const { id: cartId } = state

    if (quantity === 0) {
      const lineIds = [id]
      return removeItem({
        variables: {
          id: cartId,
          lineIds,
        },
      })
    }

    const lines: CartLineUpdateInput[] = edges
      .map((item) => {
        if (item.node.id === id) {
          return {
            id,
            quantity,
          }
        }
        return {
          id: item.node.id,
          quantity: item.node.quantity,
        }
      })
      .filter((i) => i.quantity > 0)

    return updateCart({
      variables: {
        lines,
        cartId,
      },
    })

    // persistCart(cart.items)
  }
  // look max queantify for product
  const findMaxQuantity = (items: ProductVariant) => {
    const { id, quantityAvailable } = items
    const item = state.lines?.edges.find((i) => i.node.merchandise.id === id)
    return item?.node.quantity === quantityAvailable
  }
  // add filter params
  const addLines = async (items: ProductVariant[], filter = false) => {
    if (filter) {
      if (findMaxQuantity(items[0])) {
        return null
      }
    }
    const data: CartLineInput[] = items
      .map((i) => ({
        merchandiseId: i.id,
        quantity: 1,
      }))
      .filter((i) => i.quantity > 0)

    const { id, lines } = state
    if (!id || !lines || lines?.edges.length === 0) {
      await create({
        variables: {
          input: {
            lines: data,
          },
        },
      })
      return createRes
    }

    await cartAdd({
      variables: {
        id,
        lines: data,
      },
    })
    return addRes
  }

  /**
   * Cart Visible action
   */
  const show = () => {
    dispatch({ type: 'toggle', open: true })
  }
  const hide = () => {
    dispatch({ type: 'toggle', open: false })
  }
  const toggle = () => {
    dispatch({ type: 'toggle', open: !state.open })
  }

  /**
   * Cart checkout
   */
  const toCheckout = async () => {
    const { checkoutUrl } = state
    const token = getToken()
    const email = getEmail()

    if (!token || !email) {
      window.location.href = checkoutUrl
    } else {
      const res = await fetch('/api/user/login', {
        method: 'POST',
        body: JSON.stringify({
          email,
          return_to: checkoutUrl,
        }),
      })
      const body = await res.json()
      window.location.href = body.data.url
    }
  }

  const productCount = useMemo(() => {
    const num = state.lines?.edges.reduce(
      (acc, item) => acc + item.node.quantity,
      0
    )
    return num
  }, [state.lines])

  useEffect(() => {
    persistCart(state)
  }, [state])

  useEffect(() => {
    if (router.query.from && router.query.from === 'checkout') {
      show()
    }
  }, [router.query])

  return {
    state,
    productCount,
    show,
    hide,
    toggle,
    update,
    updateRes,
    checkout,
    createRes,
    addLines,
    loading,
    toCheckout,
  }
}
export default useCart
