import React, { FC, PropsWithChildren, MouseEventHandler } from 'react'
import dynamic from 'next/dynamic'

interface Props {
  count: number
  color?: string
  onClick?: MouseEventHandler
}

const Badge: FC<PropsWithChildren<Props>> = (props) => {
  const { count, color = '#ff4f33', children, onClick } = props

  return (
    <div className="relative flex items-center" onClick={onClick}>
      {count > 0 && (
        <div
          className={`badge z-10${count > 99 ? ' px-1 -right-5' : ''}`}
          style={{ backgroundColor: color }}
        >
          {count > 99 ? '99+' : count || 0}
        </div>
      )}
      {children}
    </div>
  )
}

export default dynamic(() => Promise.resolve(Badge), {
  ssr: false,
})
