import React from 'react'
import Image, { ImageLoader, ImageProps } from 'next/image'

const shimmer = (
  width?: string | number,
  height?: string | number,
  title?: string
) => `
  <svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    ${title ? `<title>${title || 'loading'}<title>` : ''}

    <rect width="${width}" height="${height}" fill="#F5F5F5" />
    <rect id="r" width="${width}" height="${height}" fill="url(#g)" />
  </svg>`

const loader: ImageLoader = ({ src, width }) => {
  if (!src) return src
  const [url, search] = src.split('?')
  let imagePath = `${url.replace(
    /(.+)(\.(jpg|png|webp|jpeg|gif))/,
    `$1_${width}x$2`
  )}`
  if (search) imagePath += `?${search}`

  return imagePath
}

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)

const ShopifyImage = (props: ImageProps) => {
  const { src, width, height, title } = props
  const prop: ImageProps = {
    placeholder: 'blur',
    blurDataURL: `data:image/svg+xml;base64,${toBase64(
      shimmer(width, height, title)
    )}`,
    // blurDataURL: loader({
    //   src: src as string,
    //   width: Math.ceil((width as number) / 10),
    // }),
    ...props,
  }

  if (!src) {
    return (
      <div
        className="w-full"
        style={{
          paddingTop: `${
            (parseInt(height as string, 10) / parseInt(width as string, 10)) *
            100
          }%`,
        }}
      />
    )
  }
  return <Image loader={loader} {...prop} />
}

export const BaseImage = (props: ImageProps) => {
  const { src, width, height, title } = props
  const prop: ImageProps = {
    placeholder: 'blur',
    blurDataURL: `data:image/svg+xml;base64,${toBase64(
      shimmer(width, height, title)
    )}`,
    ...props,
  }

  if (!src) {
    return (
      <div
        className="w-full"
        style={{
          paddingTop: `${
            (parseInt(height as string, 10) / parseInt(width as string, 10)) *
            100
          }%`,
        }}
      />
    )
  }
  return <Image loader={({ src: imgSrc }) => imgSrc} {...prop} />
}

export default ShopifyImage
