import { ProductFilterKeys } from '../../../types/product'
import {
  FilterValue,
  PriceRangeFilter,
  VariantOptionFilter,
} from '../../../types/storefront'
import { FilterObject } from './filters'

export const SIZE_MAP = {
  '12': ['12/14', '12-14', '12/l', '12/x'],
  '14': ['12/14', '12-14', '14/1x'],
  '16': ['16/18', '16-18', '16/1x', '16/l', '16/2x', '16/1xl'],
  '18': ['16/18', '16-18', '18/2x', '18/1x', '18/2xl'],
  '20': ['20/22', '20-22', '20/2x', '20/1x'],
  '22': ['20/22', '20-22', '22/3x', '22/3xl'],
  '24': ['24/3x', '24/2x'],
  // '26/4x': ['26/4x', '26/3x'],
  'One size': ['One size', 'one size', 'One Size'],
}

export const SIZE_FILTER_SET = Object.keys(SIZE_MAP).map<FiterObject>((key) => {
  const params = SIZE_MAP[key].map<Record<'variantOption', any>>((i) => ({
    variantOption: {
      name: 'Size',
      value: i,
    },
  }))

  return {
    count: 0,
    type: ProductFilterKeys.size,
    input: JSON.stringify(params),
    id: key,
    label: key,
  }
})
