import {
  ProductCollectionSortKeys,
  ProductSortKeys,
} from '../../../types/storefront'

export interface SortItem<T> {
  label: string
  value: T | ''
  reverse?: boolean
  searchValue: string
}

export const CART_ID = 'CART_ID'
export const CART_ITEMS = 'CART_ITEMS'
export const TOKEN = 'USER_TOKEN'
export const EMAIL_CONSTANT = '_email'
export const TOKEN_EXPIRES = 'UTE'

export const COLLECTION_PREFIX = 'collections'
export const SHOPIFY_CDN_PATH = '//cdn.shopify.com/s/files/1/0510/7171/3450'

export const FOOTER_ITEMS = [
  {
    title: 'COMPANY INFO',
    open: false,
    children: [
      {
        title: 'About Us',
        href: '/pages/about-us-2',
        name: 'about-us-2',
      },
      {
        title: 'Privacy Policy',
        href: '/pages/social-responsibility',
        name: 'social-responsibility',
      },
      {
        title: 'Terms & Conditions',
        href: '/pages/terms-conditions',
        name: 'terms-conditions',
      },
      {
        title: 'Copyright Notice',
        href: '/pages/copyright-notice',
        name: 'copyright-notice',
      },
    ],
  },
  {
    title: 'CUSTOMER CARE',
    open: false,
    children: [
      {
        title: 'Contact Us',
        href: '/pages/contact-1',
        name: 'contact-1',
      },
      {
        title: 'Payment Method',
        href: '/pages/payment-method',
        name: 'payment-method',
      },
      {
        title: 'Bloom Survey',
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSft1DtLqwm3qKSgkSgJ68x7Nc0nSRkvBR2en1Op07_HXCc5AA/viewform',
        name: '',
      },
      {
        title: 'Affiliate',
        href: 'https://af.uppromote.com/BloomChicAffiliate/register',
        name: '',
      },
    ],
  },
  {
    title: 'HELP & SUPPORT',
    open: false,
    children: [
      {
        title: 'Shipping Info',
        href: '/pages/shipping-1',
      },
      {
        title: 'Return Policy',
        href: '/pages/return-policy',
      },
      {
        title: 'Track My Orders',
        href: 'https://bloomchic.com/apps/tracking#/',
      },
      {
        title: 'FAQ',
        href: '/pages/faq2',
      },
    ],
  }
]

export const SOCIALS = [
  {
    id: 'social_instagram_link',
    label: 'Instagram',
    info: 'https://www.instagram.com/bloomchicfashion/',
  },
  {
    id: 'social_facebook_link',
    label: 'Facebook',
    info: 'https://www.facebook.com/BLOOMCHICOFFICIAL',
  },
  {
    type: 'text',
    id: 'social_youtube_link',
    label: 'YouTube',
    info: 'https://www.youtube.com/channel/UCOsiyXPLBzEWze8kqD8PIFw',
  },
  {
    id: 'social_pinterest_link',
    label: 'Pinterest',
    info: 'https://www.pinterest.com/BloomChic_Official/_created/',
  },
  {
    id: 'social_snapchat_link',
    label: 'Snapchat',
    info: 'https://www.snapchat.com/add/bloom-chic',
  },
  {
    id: 'social_tiktok_link',
    label: 'TikTok',
    info: 'https://www.tiktok.com/@bloomchicfashion',
  },
]

export const CollectionSortList: SortItem<ProductCollectionSortKeys>[] = [
  {
    label: 'Featured',
    value: ProductCollectionSortKeys.CollectionDefault,
    searchValue: 'manual',
  },
  {
    label: 'Sort',
    value: ProductCollectionSortKeys.Title,
    searchValue: 'title-ascending',
  },
  {
    label: 'Best selling',
    value: ProductCollectionSortKeys.BestSelling,
    searchValue: 'best-selling',
  },
  {
    label: 'Price, low to high',
    value: ProductCollectionSortKeys.Price,
    searchValue: 'price-ascending',
  },
  {
    label: 'Price, high to low',
    value: ProductCollectionSortKeys.Price,
    reverse: true,
    searchValue: 'price-descending',
  },
  {
    label: 'Date, old to new',
    value: ProductCollectionSortKeys.Created,
    searchValue: 'created-ascending',
  },
  {
    label: 'Date, new to old',
    value: ProductCollectionSortKeys.Created,
    reverse: true,
    searchValue: 'created-descending',
  },
]

export const ProductSortList: SortItem<ProductSortKeys>[] = [
  {
    label: 'Sort',
    value: '',
    searchValue: '',
  },
  {
    label: 'Best selling',
    value: ProductSortKeys.BestSelling,
    searchValue: 'best-selling',
  },
  {
    label: 'Price, low to high',
    value: ProductSortKeys.Price,
    searchValue: 'price-ascending',
  },
  {
    label: 'Price, high to low',
    value: ProductSortKeys.Price,
    reverse: true,
    searchValue: 'price-descending',
  },
  {
    label: 'Date, old to new',
    value: ProductSortKeys.CreatedAt,
    searchValue: 'created-ascending',
  },
  {
    label: 'Date, new to old',
    value: ProductSortKeys.CreatedAt,
    reverse: true,
    searchValue: 'created-descending',
  },
]

export const DISCOUNT_SET = [
  {
    min: 0,
    max: 39,
    coupon: 5,
  },
  {
    min: 39,
    max: 59,
    coupon: 'Free Standard Shipping',
  },
  {
    min: 59,
    max: 69,
    coupon: 10,
  },
  {
    min: 69,
    max: 99,
    coupon: 15,
  },
  {
    min: 99,
    max: 129,
    coupon: 20,
  },
  {
    min: 129,
    max: 159,
    coupon: 25,
  },
  {
    min: 159,
    max: Infinity,
    coupon: 25,
  },
]
