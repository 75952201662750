/* eslint-disable no-nested-ternary */
import React from 'react'
import Script from 'next/script'

export default function SensorsScript() {
  return (
    <Script
      id="sensor-script"
      dangerouslySetInnerHTML={{
        __html: `
    (function(para) {
    var p = para.sdk_url, n = para.name, w = window, d = document, s = 'script',x = null,y = null;
    if(typeof(w['sensorsDataAnalytic201505']) !== 'undefined') {
        return false;
    }
    w['sensorsDataAnalytic201505'] = n;
    w[n] = w[n] || function(a) {return function() {(w[n]._q = w[n]._q || []).push([a, arguments]);}};
    var ifs = ['track','quick','register','registerPage','registerOnce','trackSignup', 'trackAbtest', 'setProfile','setOnceProfile','appendProfile', 'incrementProfile', 'deleteProfile', 'unsetProfile', 'identify','login','logout','trackLink','clearAllRegister','getAppStatus','setItem','deleteItem'];
    for (var i = 0; i < ifs.length; i++) {
      w[n][ifs[i]] = w[n].call(null, ifs[i]);
    }
    if (!w[n]._t) {
      x = d.createElement(s), y = d.getElementsByTagName(s)[0];
      x.async = 1;
      x.src = p;
      x.setAttribute('charset','UTF-8');
      w[n].para = para;
      y.parentNode.insertBefore(x, y);
    }
  })({
    sdk_url: 'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/t/87/assets/sdata.min.js?v=3488065863168548185',
    name: 'sensors',
    server_url: 'https://unicorn.bloomchic.com/sa?project=${
      process.browser
        ? window.location.host.indexOf('shop') === 0
          ? 'production'
          : 'default'
        : 'default'
    }',
    show_log: false,
    heatmap: {
      clickmap: 'default',
      scroll_notice_map: 'default',
    },
    is_track_single_page: true,
    use_client_time: true,
    send_type: 'beacon',
    is_track_device_id: true,
    preset_properties: {
      latest_utm: true,
      latest_traffic_source_type: true,
      latest_search_keyword: true,
      latest_referrer: true,
      latest_referrer_host: true,
      latest_landing_page: true,
      url: true,
      title: true,
    },
  });
    `,
      }}
    />
  )
}
