export const getPageCode = () => {
  if (typeof window === 'undefined') return ''
  let pageCode = '其他'
  const { pathname } = window.location
  if (pathname === '/') {
    pageCode = '首页'
  } else if (pathname.includes('/products/')) {
    pageCode = '商品详情页'
  } else if (pathname.includes('/collections/')) {
    pageCode = '商品列表'
  }
  return pageCode
}

export const pageLife = {
  start() {
    window.landingTime = new Date()
  },
  getStayTime(): Number {
    const now = new Date()
    return Number(
      now.getTime().sub(window.landingTime.getTime()).div(1000).toFixed(2)
    )
  },
}
