/* eslint-disable @next/next/no-script-in-head */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, ReactNode, useEffect } from 'react'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import { ConfirmProvider } from 'material-ui-confirm'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import Script from 'next/script'
// Types
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { CartProvider } from '../hooks/useCart'
// import { ProductViewProvider } from '../hooks/useProductViews'
import { GlobalDataProvider } from '../hooks/useGlobalDatas'
import Layout from '../components/Layout/layout'
import { useApollo } from '../api/client'
import initPixel from '../utils/pixel/AllPixel'
import { initRouterListeners } from '../utils/scroll-handler'
import Udesk from '../utils/plugins/udesk'
import Sensors from '../utils/bloomchicdata/scripts/Sensors'
// eslint-disable-next-line import/order
import themeConfig from '../theme/theme'
import { BasePageSensor } from '../utils/bloomchicdata/page/basepage'
import { useIsomorphicLayoutEffect } from '../utils/helpers'
// Styles
import 'material-react-toastify/dist/ReactToastify.css'
import '../assets/styles/globals.css'

let basePageSensor: BasePageSensor | null
const basePageSensorMod = () => import('../utils/bloomchicdata/page/basepage')

const Cart = dynamic(() => import('../components/Cart'), { ssr: false })
const Lottery = dynamic(() => import('../components/lottery'), { ssr: false })
// import { ToastContainer } from 'material-react-toastify'
const ToastContainer = dynamic(
  // @ts-ignore
  () => import('material-react-toastify').then((mod) => mod.ToastContainer),
  { ssr: false }
)
const Zoom = dynamic(
  // @ts-ignore
  () => import('material-react-toastify').then((mod) => mod.Zoom),
  { ssr: false }
)
// const ProductViews = dynamic(() => import('../components/ProductViews'))

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const allPixels = initPixel()

initRouterListeners()

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { getLayout } = Component
  const apolloClient = useApollo(pageProps.initialApolloState)

  const router = useRouter()

  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      const loadScriptByURL = (id: string, url: string, callback: any) => {
        const isScriptExist = document.getElementById(id)

        if (!isScriptExist) {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = url
          script.id = id
          script.onload = () => {
            if (callback) callback()
          }
          document.body.appendChild(script)
        }

        if (isScriptExist && callback) callback()
      }

      // load the script by passing the URL
      loadScriptByURL(
        'recaptcha-key',
        `${process.env.NEXT_PUBLIC_RECAPTCHA_URL}/api.js?render=${process.env.NEXT_PUBLIC_SITE_KEY}&lang=en&hl=en`,
        () => {
          console.log('ReCaptcha Script loaded!')
        }
      )

      basePageSensorMod().then((mod) => {
        basePageSensor = mod.default
        if (typeof window !== 'undefined') {
          // @ts-ignore
          window.$BasePageSensor = basePageSensor
        }
      })
    }
  }, [])

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles)
    }
    // if (process.browser) {
    //   allPixels.viewPage()
    // }
  }, [])

  useEffect(() => {
    if (process.browser) {
      setTimeout(() => allPixels.viewPage(), 3000)
    }
  }, [router.asPath])

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={themeConfig}>
        <StyledEngineProvider injectFirst>
          <ConfirmProvider>
            <CartProvider>
              {/* <ProductViewProvider> */}
              <GlobalDataProvider>
                <Head>
                  <title>
                    BloomChic | Shop Trendy Plus Size Women&apos;s Fashion
                  </title>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
                  />
                  <meta content="yes" name="apple-mobile-web-app-capable" />
                  <meta
                    httpEquiv="Content-Security-Policy"
                    content="upgrade-insecure-requests"
                  />
                  <script
                    dangerouslySetInnerHTML={{
                      __html: allPixels.script,
                    }}
                  />
                  <noscript
                    id="pixel-noscript"
                    dangerouslySetInnerHTML={{
                      __html: allPixels?.noScript ?? '',
                    }}
                  />
                </Head>
                {/* Pixel scripts */}
                <Sensors />
                <Script
                  strategy="lazyOnload"
                  type="text/javascript"
                  src="https://af.uppromote.com/tracking_third_party.js?shop=lavisheus.myshopify.com"
                />
                {/* <ScaAffiliate /> */}
                {allPixels.scriptHref.map((i) => (
                  <Script strategy="lazyOnload" src={i} key={i} />
                ))}

                {getLayout ? (
                  getLayout(<Component {...pageProps} />)
                ) : (
                  <Layout showDiscount>
                    <Component {...pageProps} />
                  </Layout>
                )}

                {typeof window !== 'undefined' &&
                router.route.indexOf('/reviews') < 0 ? (
                  <>
                    <Udesk />
                    <Cart />
                    <Lottery />
                  </>
                ) : null}

                <ToastContainer
                  position="top-center"
                  autoClose={3500}
                  hideProgressBar
                  newestOnTop
                  // @ts-ignore
                  transition={Zoom}
                  closeOnClick={false}
                  rtl={false}
                  draggable
                  pauseOnHover={false}
                  closeButton={<></>}
                />
                {/* </ProductViewProvider> */}
              </GlobalDataProvider>
            </CartProvider>
          </ConfirmProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  if (process.env.NODE_ENV === 'production') {
    const data = {
      metric_id: id,
      label,
      value,
    }
    setTimeout(() => {
      window.sensors?.track(name, data)
      window.gtag('event', name, {
        event_category:
          label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        non_interaction: true, // avoids affecting bounce rate.
        ...data,
      })
    }, 0)
  }
}

export default MyApp
