class Money {
  formatTag = 'PRICE'

  money: number

  priceSymbol = '$'

  constructor(price: number | string) {
    this.money = Number(price)
    if (Number.isNaN(this.money)) {
      throw new Error(`Illegal number ${price}.`)
    }
  }

  format(format: string) {
    return format.replace(this.formatTag, String(this.money))
  }

  formatPrice(length: number) {
    return `${this.priceSymbol}${this.money.toFixed(length)}`
  }

  calcDiscount(price: number, comparePrice: number) {
    if (!comparePrice) {
      this.money = 0
    } else {
      const discount = Math.round(((comparePrice - price) / comparePrice) * 100)
      this.money = discount
    }

    return this
    // return new Money(discount).format('PRICE%')
  }

  value() {
    return this.money
  }
}

export default function money(price: number | string = 0) {
  return new Money(price)
}
