/* eslint-disable class-methods-use-this */
import {
  ProductByHandleQuery,
  ProductVariant,
  Cart as CartStoreFront,
} from 'storefront'
import IPixel, { IPixelCartItem } from './IPixel'
import facebookPixel from './facebookPixel'
import tiktokPixel from './tiktokPixel'
import pinterestPixel from './pinterestPixel'
import googlePixel from './googlePixel'
import bingPixel from './bingPixel'

export class AllPixels {
  scriptHref: string[] = []

  script: string

  noScript: string

  getPfunc() {
    return null
  }

  pixels: IPixel[]

  viewPage(): void {
    this.pixels.forEach((pixel) => {
      pixel.viewPage()
    })
  }

  viewProduct(productInfo: ProductByHandleQuery['product']): void {
    const product = {
      category: productInfo?.productType ?? '',
      id: window.atob(productInfo?.id ?? '').replace(/\D/g, ''),
      first_variant_id: window
        .atob(productInfo?.variants.edges[0].node.id ?? '')
        .replace(/\D/g, ''),
      name: productInfo?.title ?? '',
      value: Number(productInfo?.variants.edges[0].node.price),
      currency: 'USD',
    }
    this.pixels.forEach((pixel) => {
      pixel.viewProduct(product)
    })
  }

  addToCart(
    productInfo: ProductByHandleQuery['product'],
    productVariant: ProductVariant
  ): void {
    const product = {
      category: productInfo?.productType ?? '',
      id: window.atob(productInfo?.id ?? '').replace(/\D/g, ''),
      first_variant_id: window
        .atob(productInfo?.variants.edges[0].node.id ?? '')
        .replace(/\D/g, ''),
      name: productInfo?.title ?? '',
      value: Number(productVariant.price),
      currency: 'USD',
    }
    this.pixels.forEach((pixel) => {
      pixel.addToCart(product)
    })
  }

  placeAnOrder(): void {
    this.pixels.forEach((pixel) => {
      pixel.placeAnOrder()
    })
  }

  completePayment(): void {
    this.pixels.forEach((pixel) => {
      pixel.completePayment()
    })
  }

  initiateCheckout(cart: CartStoreFront | undefined): void {
    const cartInfo: IPixelCartItem[] = []
    cart?.lines.edges.forEach((item) => {
      cartInfo.push({
        first_variant_id: window.atob(item.node.merchandise.id),
        category: item.node.merchandise.product.productType,
        id: window.atob(item.node.id),
        name: item.node.merchandise.product.title,
        value: item.node.merchandise.priceV2.amount,
        currency: 'USD',
        quantity: item.node.quantity,
      })
    })
    this.pixels.forEach((pixel) => {
      pixel.initiateCheckout(cartInfo)
    })
  }

  constructor(pixels: IPixel[]) {
    this.script = ''
    this.noScript = ''
    this.pixels = []
    pixels.forEach((pixel) => {
      if (pixel.scriptSrc) this.scriptHref.push(pixel.scriptSrc)
      this.pixels.push(pixel)
      this.script += pixel.script
      this.noScript += pixel.noScript
    })
  }
}

let allPixels: AllPixels | null = null
export default function init() {
  if (!allPixels) {
    const pixelArray: IPixel[] = []
    // 先注释
    pixelArray.push(facebookPixel)
    // pixelArray.push(tiktokPixel)
    pixelArray.push(pinterestPixel)
    pixelArray.push(googlePixel)
    pixelArray.push(bingPixel)
    allPixels = new AllPixels(pixelArray)
  }

  if (typeof window !== 'undefined') {
    window.allPixels = allPixels
  } else {
    // eslint-disable-next-line no-console
    console.warn('allPixels init fail')
  }

  return allPixels
}
