/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react'

interface Props {
  className?: string
  width?: number | string
  height?: number | string
  name: string
  onClick?: () => void
}

const Icon: FC<Props> = (props) => {
  const { className = '', width = 24, height = 24, name, onClick } = props
  return (
    <span onClick={() => onClick?.()}>
      <img
        src={`/svg/${name}.svg`}
        width={width}
        height={height}
        className={className}
        alt="icon"
      />
    </span>
  )
}

export default Icon
