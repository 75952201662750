/* eslint-disable class-methods-use-this */
import IPixel, { IPixelProduct } from './IPixel'

export class PinterestPixel implements IPixel {
  script: string = `
    !function(e){if(!window.pintrk){window.pintrk = function () {
    window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
      n=window.pintrk;n.queue=[],n.version="3.0";var
      t=document.createElement("script");t.async=!0,t.src=e;var
      r=document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
    pintrk('load', '2612441046645', {em: '<user_email_address>'});`

  noScript: string = `<img height="1" width="1" style="display:none" alt="" src="https://ct.pinterest.com/v3/?event=init&tid=2612441046645&pd[em]=<hashed_email_address>&noscript=1"/>`

  getPfunc() {
    return window.pintrk
  }

  viewPage(): void {
    this.getPfunc?.()('page', {
      page_name: document.getElementsByTagName('title')[0].text.trim(),
      page_category: 'My Page Category', // 与Ray沟通确认，写死即可
    })
    this.getPfunc?.()('track', 'PageView')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  viewProduct(product: IPixelProduct): void {}

  viewCollection(): void {}

  addToCart(product: IPixelProduct): void {
    const data = {
      value: Number(product.value),
      order_quantity: 1,
      currency: 'USD',
      line_items: [
        {
          product_id: product.id,
          product_category: product.category,
        },
      ],
    }
    this.getPfunc?.()('track', 'addtocart', data)
  }

  placeAnOrder(): void {}

  completePayment(): void {}

  initiateCheckout(): void {}
}

const pinterestPixel = new PinterestPixel()
export default pinterestPixel
