/* eslint-disable import/prefer-default-export */
import { TypePolicies } from '@apollo/client'

export const collectionPolicy: TypePolicies = {
  Collection: {
    fields: {
      products: {
        keyArgs: false,
        merge(existing, incoming, { args }) {
          if (!existing || !args?.after) return incoming

          return {
            ...existing,
            ...incoming,
            edges: [...existing.edges, ...incoming.edges],
          }
        },
      },
    },
  },
}
