import React, { FC, ReactNode } from 'react'
import classnames from 'classnames'

interface Props {
  msg: string
  content?: ReactNode
  type?: 'error' | 'success'
  className?: string
}

const Message: FC<Props> = (props) => {
  const { msg, content, className, type = 'error' } = props
  const typeClass: Record<string, string> = {
    error: 'border-red-600 text-red-600 bg-red-50',
    success: 'border-green-600 text-green-600 bg-green-50',
  }

  return (
    <div
      className={classnames([
        'border text-tiny sm:text-sm py-2 pl-8 pr-4 font-medium',
        typeClass[type],
        className,
      ])}
    >
      <span className="list-item list-disc">{content || msg}</span>
    </div>
  )
}

export default Message
