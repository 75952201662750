import React, { FC, useMemo } from 'react'
import { IoMdStar } from 'react-icons/io'

/**
 * 打分组件 Ranting
 * value range：1 ≤ value ≤ length
 */
// eslint-disable-next-line import/prefer-default-export
export const Rating: FC<{
  length?: number
  value?: number | string
  disabled?: boolean
  className?: string
  StarClassName?: string
  openHalf?: boolean
  onSelect?: (e: any) => void
  type?: string
  func?: (v: number) => number
}> = ({
  length = 5,
  value = 0,
  disabled,
  className = '',
  StarClassName = '',
  openHalf = true,
  onSelect,
  type = 'outline',
  // 半星模式开启时的计算函数：默认函数0.25为四舍五入阈值，类似这样：3.1=>3, 3.24=>3, 3.25=>3.5, 3.4=>3.5, 4.74=>4.5, 4.75=>5
  func = (v: number) => Math.round(v * 2) / 2,
}) => {
  const finalValue = useMemo(
    () => (openHalf ? func(Number(value)) : Math.round(Number(value))),
    [openHalf, func, value]
  )

  function onHandleSelect(index: number) {
    if (disabled) {
      return
    }
    onSelect?.(index)
  }
  return (
    <div className={className} style={{ cursor: 'none' }}>
      {Array(length)
        .fill(0)
        .map((i: any, idx) => (
          <IoMdStar
            key={`star-item${idx}`}
            data-start={idx}
            className={`reviews-star inline-block cursor-pointer ${
              finalValue - idx > 0 ? 'star-on' : 'star-off'
            } reviews-star-${type || 'outline'} ${
              openHalf && finalValue - idx === 0.5 ? 'star-half' : ''
            } ${StarClassName}`}
            onClick={() => onHandleSelect(idx + 1)}
            data-val={idx + 1}
          />
        ))}
    </div>
  )
}
