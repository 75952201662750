import Router from 'next/router'
import initialzeApollo from '../api/client'

let routes: { pathname: string; scrollY: number }[] = []

export const routeHistory = routes

/**
 * 动画垂直滚动到页面指定位置
 * @param { Number } targetY 目标位置
 * @param { Number } speed 速度
 */
export function scrollAnimation(targetY: number, speed = 5) {
  let currentY = document.documentElement.scrollTop || document.body.scrollTop
  const needScrollTop = targetY - currentY
  requestAnimationFrame(() => {
    const dist = Math.ceil(needScrollTop / speed)
    currentY += dist
    window.scrollTo(0, currentY)

    if (needScrollTop > speed || needScrollTop < -speed) {
      scrollAnimation(targetY)
    } else {
      window.scrollTo(0, targetY)
    }
  })
}

export function initRouterListeners() {
  // const routes: any[] = []

  function pushCurrentRouteInfo() {
    if (routes.length >= 50) {
      routes = routes.splice(routes.length - 2, 2)
    }
    routes.push({ pathname: Router.asPath, scrollY: window.scrollY })
  }

  function isBack() {
    return (
      routes.length >= 2 && Router.asPath === routes[routes.length - 2].pathname
    )
  }

  function fixScrollPosition() {
    let scrollY = 0
    if (isBack()) {
      const targetRoute = routes[routes.length - 2]
      scrollY = targetRoute?.scrollY || 0
    }

    setTimeout(() => {
      window.scrollTo(0, scrollY)
      document.body.style.opacity = '1'
    }, 0)
  }

  Router.events.on('routeChangeStart', () => {
    pushCurrentRouteInfo()
    // document.body.style.opacity = '0.7'
    document.body.classList.add('fade-in-leave')
    // @ts-ignore
    window.scrollLock = true
  })

  Router.events.on('routeChangeComplete', (path: string) => {
    // window.requestAnimationFrame(() => window.scrollTo(0, 1))
    // console.log(path)

    // Clear cache after leaved collection or product page
    if (!['collections', 'products'].some((p) => path.indexOf(p) >= 0)) {
      const client = initialzeApollo()
      client.cache.reset()
    }
    scrollAnimation(0, 0)
    document.body.classList.remove('fade-in-leave')
    fixScrollPosition()
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    requestAnimationFrame(() => (window.scrollLock = false))
  })
}
