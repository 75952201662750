/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, FC } from 'react'
import classnames from 'classnames'

// @ts-ignore
import { PropsWithChildren } from 'react-transition-group/node_modules/@types/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { InView } from 'react-intersection-observer'
import Image from './Image'
import money from '../utils/money'
import useProduct from '../hooks/useProduct'
// import useProductViews from '../hooks/useProductViews'
import { CollectionProductFragment } from '../../types/storefront'
import { BasePageSensor } from '../utils/bloomchicdata/page/basepage'
import { Rating } from './products/reviews/Rating'
import { useIsomorphicLayoutEffect } from '../utils/helpers'

const basePageSensorMod = () => import('../utils/bloomchicdata/page/basepage')

let basePageSensor: BasePageSensor | null
interface Props {
  data: CollectionProductFragment
  className?: string
  productIndex: Number
  collectionTitle?: string
  hiddenRating?: boolean
  moduleName?: string // Only for Data Report
  collectionId?: string
  // hiddenQucikView?: boolean
}

export const ProductTitle = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={`text-12 overflow-ellipsis overflow-hidden whitespace-nowrap text-gray-500 mt-1.5 mb-1 ${
      className || ''
    }`}
  >
    {children}
  </div>
)

const Product: FC<Props> = (props) => {
  const {
    data,
    className,
    productIndex,
    collectionTitle,
    hiddenRating = false,
    moduleName,
    collectionId,
    // hiddenQucikView = false,
  } = props
  const router = useRouter()
  // const { openViews2 } = useProductViews()
  const { selectedColor, colorsWithTagStyle, colors, isNew, imagesMapOfColor } =
    useProduct(data)
  const [firstImg, setFirstImg] = useState<string>(
    data.images.edges[0]?.node.transformedSrc
  )
  const [secondImg, setSecondImg] = useState<string>('')
  const [hoverImg, setHoverImg] = useState<string>('')
  const findProductReviews = (datas: CollectionProductFragment) => {
    let value = datas.metafields.edges.find(
      (item) => item.node.key === 'reviews' && item.node.namespace === 'Product'
    )
    if (value) {
      value = JSON.parse(value.node.value)
    }
    if (Array.isArray(value)) {
      return value
    }
    return ''
  }
  // const ref = useRef<HTMLDivElement | null>(null)
  const ratingNum = useMemo(() => {
    const reviewsData = findProductReviews(data)
    if (reviewsData && reviewsData?.length) {
      return {
        value: reviewsData[1].average,
        count: reviewsData[0].count,
      }
    }
    const value = data.metafield?.value
    if (!value) return 0
    let str = ''
    if (value.indexOf('<') === 0) {
      str = value
    } else {
      str = window.atob(value)
    }
    // console.log('str', str)
    return {
      value:
        Number(/data-average-rating=['"]([^'"]+)['"]+/g.exec(str)?.['1']) || 0,
      count: Number(
        /data-number-of-reviews=['"]([^'"]+)['"]+/g.exec(String(str))?.['1'] ||
          0
      ),
    }
    // return Number(/data-average-rating=['"]([^'"]+)['"]+/g.exec(str)?.['1'])
  }, [data])
  const productHref = useMemo(
    () =>
      `/products/${data.handle}${
        collectionTitle
          ? `?collectionTitle=${encodeURIComponent(
              collectionTitle.replace(/\s/g, '-').toLocaleLowerCase()
            )}`
          : ''
      }`,
    [data.handle, collectionTitle]
  )
  const hoverColorImage = (hoverColor: string) => {
    const id = imagesMapOfColor[hoverColor]?.id
    const image = data.images.edges.find((i) => i.node.id === id)
    setHoverImg(image?.node.originalSrc || image?.node.transformedSrc)
  }
  function getImg(datas: any) {
    const list = datas.images.edges
    let result = list[0]?.node.transformedSrc
    setFirstImg(result)
    const length = list?.length
    let index = 1
    while (length > index) {
      if (list[index].node.transformedSrc !== result) {
        result = list[index].node.transformedSrc
        break
      } else {
        index += 1
      }
    }
    setSecondImg(result || '')
  }
  // function handleOpenViews(e: any) {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   openViews2(data.handle)
  // }
  function goProduct(color: string, e: any) {
    e.preventDefault()
    const id = imagesMapOfColor[color]?.id
    router.push(`${productHref}${collectionTitle ? '&' : '?'}variant=${id}`)
  }

  useEffect(() => {
    if (data) {
      getImg(data)
    }
  }, [data])

  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      basePageSensorMod().then((mod) => {
        basePageSensor = mod.default
      })
    }
  }, [])

  return (
    <InView
      initialInView={false}
      triggerOnce
      threshold={1}
      onChange={(e) => {
        if (e) {
          basePageSensor?.ProductShow({
            ...data,
            productIndex,
            moduleName: moduleName || collectionTitle,
            collectionName:
              window.location.pathname.split('/')[1] === 'collections'
                ? `商品列表页-${
                    window.atob(collectionId || '').replace(/\D/g, '') ?? ''
                  }`
                : undefined,
            url: `/products/${data.handle}`,
            sku: selectedColor.data.sku,
            oPrice: selectedColor.data.compareAtPriceV2
              ? (
                  selectedColor.data.compareAtPriceV2 ??
                  selectedColor.data.priceV2
                ).amount
              : 0,
            cPrice: selectedColor.data.priceV2
              ? selectedColor.data.priceV2.amount
              : 0,
          })
        }
      }}
    >
      <div
        className={`${className} relative product-item`}
        onClick={() => {
          if (collectionTitle) {
            basePageSensor?.ProductClick(
              data,
              selectedColor.data,
              productIndex,
              moduleName || collectionTitle
            )
          }
        }}
      >
        <div className="absolute top-0 left-0 z-10">
          {selectedColor.discount && (
            <div className="tag bg-tag-red">{selectedColor.discount}</div>
          )}
          {isNew && !selectedColor.discount && (
            <div className="tag bg-tag-green">New</div>
          )}
        </div>

        {!data.availableForSale && (
          <div className="absolute z-20 w-full top-0 bottom-0 bg-white bg-opacity-30 flex justify-center items-center">
            <span className="inline-block p-1 bg-black bg-opacity-50 text-white text-sm">
              Restocking
            </span>
          </div>
        )}

        <Link href={productHref} prefetch={false} passHref>
          <a>
            <div className="product-image relative cursor-pointer">
              {/* {hoverImg} */}
              <Image
                layout="responsive"
                sizes="180px"
                src={hoverImg || firstImg}
                alt={data.title}
                width={180}
                height={240}
              />
              <div className="second-img hidden md:block absolute top-0 left-0 z-11 w-full h-full opacity-0 transition-opacity">
                {/* {!hiddenQucikView && (
                  <div
                    className="preview-btn absolute cursor-pointer bottom-4 z-10 text-center w-11/12 py-2 text-sm left-1/2 transform -translate-x-1/2 bg-black text-white"
                    onClick={handleOpenViews}
                  >
                    Quick View
                  </div>
                )} */}
                {secondImg && (
                  <Image
                    layout="responsive"
                    src={secondImg}
                    alt={data.title}
                    width={360}
                    height={479}
                  />
                )}
              </div>
            </div>
            <div className="block w-full">
              <ProductTitle>{data.title}</ProductTitle>
            </div>
            {!hiddenRating && (
              <div
                className="flex w-full text-12 items-center pb-1"
                onClick={(e) => {
                  e.preventDefault()
                  router.push(
                    `${productHref}${collectionTitle ? '&' : '?'}target=rating`
                  )
                }}
              >
                {ratingNum && ratingNum.value ? (
                  <>
                    <Rating
                      type="outline"
                      value={ratingNum.value.toFixed(2)}
                      length={5}
                      openHalf
                      func={(v: number) =>
                        Math[v < 4.8 ? 'floor' : 'ceil'](v * 2) / 2
                      }
                      className="star-xxs inline-flex"
                    />
                    <div className="flex items-center text-12 text-gray-500 ml-1">
                      {`${ratingNum.count} review${
                        ratingNum.count > 1 ? 's' : ''
                      }`}
                    </div>
                  </>
                ) : null}
              </div>
            )}

            <div className="flex items-center">
              <span
                className={classnames('font-semibold', {
                  'text-tag-red': selectedColor.discount,
                })}
              >
                {money(selectedColor.data.priceV2?.amount).formatPrice(2)}
              </span>
              {selectedColor.discount && (
                <span className="text-14 text-tag-gray777 ml-1 line-through">
                  {money(
                    selectedColor.data.compareAtPriceV2?.amount
                  ).formatPrice(2)}
                </span>
              )}
            </div>
          </a>
        </Link>
        {colors.length > 1 && (
          <div className="flex leading-0 mt-2.5 flex-nowrap overflow-x-auto overflow-y-hidden nobar">
            {colorsWithTagStyle().map((i, idx: number) => (
              <div
                className={classnames(
                  'border mr-1.5 w-5.5 h-5.5 cursor-pointer inline-flex flex-shrink-0',
                  {
                    'border-gray-300':
                      i.color !== selectedColor.name.toLowerCase(),
                    'border-black':
                      i.color === selectedColor.name.toLowerCase(),
                    'p-px': i.color === selectedColor.name.toLowerCase(),
                    'color-active': idx === 0,
                  }
                )}
                key={i.color}
                aria-label={i.color}
                onMouseEnter={() => hoverColorImage(i.color)}
                onMouseLeave={() => hoverColorImage('')}
                onClick={(e) => goProduct(i.color, e)}
              >
                <span className="w-full h-full inline-block" style={i.style} />
              </div>
            ))}
          </div>
        )}
      </div>
    </InView>
  )
}

export default Product
