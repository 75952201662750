import React, { useState, useEffect, FC, useContext } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import { HeaderDataContext } from '../../hooks/useHeaderData'
import { BasePageSensor } from '../../utils/bloomchicdata/page/basepage'
import { useIsomorphicLayoutEffect } from '../../utils/helpers'

const basePageMod = () => import('../../utils/bloomchicdata/page/basepage')

let basePageSensor: BasePageSensor | null
interface NavData {
  title: string
  sub_links: Array<NavData>
  url?: string
}

function formatData(data: NavData) {
  const res: NavData[] = []
  let cacheIndex = -1
  data.sub_links.forEach((i: NavData, k, self) => {
    // 有孩子，直接添加到res，并清空缓存
    if (i.sub_links) {
      res.push(i)
      cacheIndex = -1
      // 没有孩子，包装成有孩子的结构：先判断缓存，还有缓存就在缓存的结构里继续加，没有的话新建结构
    } else if (cacheIndex >= 0) {
      res[cacheIndex].sub_links.push(i)
    } else {
      res.push({
        title: self[k].title,
        url: self[k].url,
        sub_links: [],
      })
      cacheIndex = res.length - 1
    }
  })
  return res
}

const Navbar: FC<{ className: string }> = ({ className }) => {
  const [navData, setNavData] = useState<Array<NavData>>()
  const [hoverOpen, setHoverOpen] = useState<boolean>(true)
  const HeaderData = useContext(HeaderDataContext)

  useEffect(() => {
    // @ts-ignore
    if (HeaderData?.sub_links?.length) {
      const res: NavData[] = []
      // @ts-ignore
      // eslint-disable-next-line array-callback-return
      HeaderData?.sub_links.map((sub1: NavData, k: number, self: NavData[]) => {
        if (sub1.sub_links) {
          const item = formatData(sub1)
          res.push({
            title: self[k].title,
            url: self[k].url,
            sub_links: item,
          })
        } else {
          res.push(sub1)
        }
      })
      setNavData(res as Array<NavData>)
    }
  }, [HeaderData])

  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      basePageMod().then((mod) => {
        // eslint-disable-next-line new-cap
        basePageSensor = mod.default
      })
    }
  }, [])

  return (
    <nav className={`main-nav text-center leading-none text-sm ${className}`}>
      {navData?.map((i) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
          className={`inline-block relative ${hoverOpen ? 'group' : ''}`}
          key={i.title}
          onMouseOver={() => setTimeout(() => setHoverOpen(true), 300)}
          onClick={() => setHoverOpen(false)}
        >
          <Link href={i.url || '#'} passHref prefetch={false}>
            <button
              className="inline-block px-4 py-2 font-medium"
              data-first={i.title}
              onClick={() => {
                basePageSensor?.NavigationBarClick([i.title])
              }}
              type="button"
            >
              {i.title}
            </button>
          </Link>
          <div className="title-bar border-b-2 border-gray-900 w-0 invisible group-hover:visible group-hover:w-full transition-all duration-500 z-0" />
          {i.sub_links && (
            <div
              className={classNames(
                'overflow-auto shadow-md bg-white opacity-0 block flex-w flex-wrap invisible left-0 top-0.1 z-10 -translate-y-2 transition-all duration-200 group-hover:visible group-hover:translate-y-1 group-hover:opacity-100 whitespace-nowrap',
                i.sub_links.filter((e) => e?.sub_links?.length).flat().length >
                  1
                  ? 'w-screen max-w-none left-0 translate-x-0 fixed'
                  : 'absolute'
              )}
            >
              <div
                className={classNames([
                  'flex sm:justify-center',
                  i.sub_links.filter((e) => e?.sub_links?.length).flat()
                    .length <= 1
                    ? 'px-2'
                    : 'lg:container py-6',
                ])}
              >
                {i.sub_links.map((n, idx) => (
                  <div
                    className="col px-3 sm:pt-7 sm:pb-4 md:py-2 flex-1 flex-shrink-0"
                    key={idx}
                  >
                    <Link href={n.url ? n.url : '#'} passHref prefetch={false}>
                      <button
                        className="py-3 block font-medium"
                        onClick={() => {
                          basePageSensor?.NavigationBarClick([i.title, n.title])
                        }}
                        type="button"
                      >
                        {n.title}
                      </button>
                    </Link>
                    {n.sub_links && (
                      <div className="row">
                        {n.sub_links.map((v) => (
                          <Link
                            href={v.url ? v.url : '#'}
                            passHref
                            key={v.url}
                            prefetch={false}
                          >
                            <button
                              className="block py-3 font-medium"
                              data-navs={[i.title, n.title, v.title].toString()}
                              type="button"
                            >
                              {v.title}
                            </button>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </nav>
  )
}

export default Navbar
