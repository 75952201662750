import { CSSProperties } from 'react'
import {
  ProductVariantFragmentFragment,
  ProductVariantEdge,
  ProductByHandleQuery,
} from 'storefront'
// eslint-disable-next-line import/no-cycle
import { pageLife } from './bloomchicdata/utils'

type VariantMap = Record<string, ProductVariantFragmentFragment[]>

export const SHOPIFY_CDN_PATH = '//cdn.shopify.com/s/files/1/0510/7171/3450'

function setVariant(map: VariantMap, type: string, d: any) {
  if (!map[type]) {
    // eslint-disable-next-line no-param-reassign
    map[type] = []
  }

  map[type].push(d)
}

export function formatVarant(variants: Array<ProductVariantEdge>) {
  const colorsSet = new Set<string>()
  const sizeSet = new Set<string>()
  const colorVariants: VariantMap = {}
  const sizeVariants: VariantMap = {}

  variants.forEach((i) => {
    i.node.selectedOptions.forEach((option) => {
      if (option.name === 'Color') {
        colorsSet.add(option.value)
        setVariant(colorVariants, option.value, i.node)
      }
      if (option.name === 'Size') {
        sizeSet.add(option.value)
        setVariant(sizeVariants, option.value, i.node)
      }
    })
  })

  return { colorsSet, sizeSet, colorVariants, sizeVariants }
}

export function getColorBackground(color: string) {
  const PREFIX = '/t/64/assets/'
  const i = color.toLocaleLowerCase().replace(/\s/g, '-')
  return {
    color,
    style: {
      backgroundImage: `url(${SHOPIFY_CDN_PATH}${PREFIX}${i}_50x.png)`,
      backgroundColor: color,
    } as CSSProperties,
  }
}

export function getProductIsAvailable(
  productInfo: ProductByHandleQuery['product']
): boolean {
  if (!productInfo) return false
  return (
    productInfo.variants.edges.filter((i: any) => i.node.availableForSale)
      .length > 0
  )
}

export function getProductSPU(
  productInfo: ProductByHandleQuery['product']
): string {
  const reg = /.{11,14}\d(?=\D)/g
  const result = reg.exec(productInfo?.variants.edges[0].node.sku ?? '')
  return !result ? '' : result[0]
}

export function ProductPageLeave(productInfo: any, reviewInfo: any): void {
  const data = {
    product_spu: getProductSPU(productInfo),
    product_name: productInfo.title,
    is_available: getProductIsAvailable(productInfo),
    stay_time: pageLife.getStayTime(),
    review_number: reviewInfo.total,
    average_overall_rating: +reviewInfo.rating,
  }
  window.sensors.track('ProductPageLeave', data)
  console.log('ProductPageLeave', data)
}
