import { toast } from 'material-react-toastify'
import router from 'next/router'
import { EMAIL_CONSTANT, TOKEN, TOKEN_EXPIRES } from '../../utils/constants'
import { getStorage } from '../../utils/helpers'

export function checkTokenExpires() {
  if (!process.browser) return false

  const expires = getStorage(TOKEN_EXPIRES)
  if (!expires || Date.parse(Date()) > Date.parse(expires)) {
    toast.dark('Login has expired, please log in again')
    return router.replace('/account/login')
  }

  return true
}

export function setToken(token: string, expires = '') {
  try {
    localStorage.setItem(TOKEN, token)
    localStorage.setItem(TOKEN_EXPIRES, expires)
  } catch (e) {
    console.error(`Set token error: ${e}`)
  }
}

export function getToken() {
  // if (!checkTokenExpires()) {
  //   return ''
  // }

  return getStorage(TOKEN)
}

export function getEmail() {
  return getStorage(EMAIL_CONSTANT)
}

export function setEmail(email: string) {
  localStorage.setItem(EMAIL_CONSTANT, email)
  localStorage.setItem('_review_email', email)
}

export function logOut() {
  const rmArrays = [
    'lottery_view',
    'lottery_checkout',
    'lottery_add_to_cart',
    'lottery_time',
    'lottery_email',
    'lottery_prize',
    'lottery_last_close_time',
    'lottery_last_auto_show_time',
    'has_order',
    'posted_rvs',
    '_nick_name',
    '_review_email',
    EMAIL_CONSTANT,
    TOKEN,
    TOKEN_EXPIRES,
  ]
  rmArrays.map((i) => localStorage.removeItem(i))
  return ''
}
