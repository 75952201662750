import React, { FC, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import dynamic from 'next/dynamic'
import Icon from './Icon'

interface Props {
  // style?: number
  type?: string
  count: number
  average: number
}

const Trustpilot: FC<Props> = (props) => {
  const { count, average, type = 'forTop' } = props
  const goTrustpilot = (): void => {
    window.sensors.track('TrustPilotClick', {
      page_code: type === 'forTop' ? '1' : '2',
    })
    window.open('https://www.trustpilot.com/review/bloomchic.com')
  }
  const starsName = useMemo(() => {
    if (average <= 4.2) {
      return 'stars-4'
    }
    if (average >= 4.3 && average <= 4.7) {
      return 'stars-4.5'
    }
    return 'stars-5'
  }, [average])

  // 风格
  return average >= 3.8 ? (
    <>
      <InView
        initialInView={false}
        triggerOnce={false}
        threshold={1}
        onChange={(e) => {
          if (e) {
            // console.log('e', e)
            window.sensors.track('TrustPilotShow', {
              page_code: type === 'forTop' ? '1' : '2',
            })
          }
        }}
      >
        {type === 'forTop' && (
          <div
            className="flex justify-center align-middle cursor-pointer"
            onClick={goTrustpilot}
          >
            <Icon name="trustpilot_reviews_top" width={65} height={16} />
            <Icon
              name={starsName}
              width={84}
              height={16}
              className="mr-4 ml-2"
            />
            <div>
              Trustscore
              <span className="trustpilot_reviews_average mx-0.5">
                {average.toFixed(1)}
              </span>
              ｜<span className="trustpilot_reviews_count mx-0.5">{count}</span>
              reviews
            </div>
          </div>
        )}
        {type === 'forBottom' && (
          <div className="pt-6 pb-2 md:hidden">
            <div
              className="flex flex-col justify-center text-center content-center relative m-auto md:hidden"
              style={{
                width: '225px',
                height: '110px',
                border: '1px solid #ccc',
              }}
            >
              <div
                className="absolute left-1/2 transform -translate-y-1/2 z-10 top-1/2 -translate-x-1/2"
                style={{
                  height: '114px',
                  width: '170px',
                  backgroundColor: '#fafafa',
                }}
              >
                <Icon
                  name="trustpilot_reviews"
                  width={108}
                  height={27}
                  className="m-auto mt-1.5"
                />
                <Icon
                  name={starsName}
                  width={139}
                  height={26}
                  className="m-auto mt-3 mb-3"
                />
                <div className="whitespace-nowrap font-medium">
                  Trustscore
                  <span className="trustpilot_reviews_average mx-1">
                    {average.toFixed(1)}
                  </span>
                  ｜
                  <span onClick={goTrustpilot} className="cursor-pointer">
                    <span className="trustpilot_reviews_count mx-1">
                      {count}
                    </span>
                    reviews
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {type === 'forFooter' && (
          <div className="hidden md:block">
            <div className="flex-auto py-4 border-b md:border-none select-none">
              <div className="text-14 flex items-center justify-between">
                <span className="text-14 font-semibold md:text-gray-400">
                  CUSTOMER REVIEWS
                </span>
              </div>
              <div className="flex mt-1.5 justify-start flex-col flex-start items-start">
                <Icon
                  name="trustpilot_reviews"
                  width={108}
                  height={75}
                  className="m-auto mt-3"
                />
                <Icon
                  name={starsName}
                  width={139}
                  height={26}
                  className="m-auto mt-3 mb-3"
                />
                <div className="whitespace-nowrap text-14 leading-4 font-medium">
                  Trustscore
                  <span className="trustpilot_reviews_average mx-1">
                    {average.toFixed(1)}
                  </span>
                  ｜
                  <span onClick={goTrustpilot} className="cursor-pointer">
                    <span className="trustpilot_reviews_count mx-1">
                      {count}
                    </span>
                    reviews
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </InView>
    </>
  ) : (
    <></>
  )
}

export default dynamic(() => Promise.resolve(Trustpilot), {
  ssr: false,
})
