import { useLayoutEffect, useEffect } from 'react'

export function getCursors(data?: any[]) {
  if (!data) return null
  const { cursor } = data[data.length - 1]
  if (!cursor) return null
  return cursor
}

export function dedup(data: []) {
  return Array.from(new Set(...data))
}

/**
 * Get localStorage with parser
 * @param key localstorage key
 * @param parser data parser, such as JSON.parse
 * @returns {T}
 */
export function getStorage<T = string>(
  key: string,
  parser?: (data: string) => T
): T | string | null {
  let data = null
  if (!process.browser) return null

  try {
    data = localStorage.getItem(key)
    if (data && parser) {
      return parser(data)
    }
    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

export function fpFix(n: number) {
  return Math.round(n * 100) / 100
}

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect
