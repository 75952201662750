import { FilterValue, PriceRangeFilter, VariantOptionFilter } from 'storefront'
import { ProductFilterKeys } from '../../../types/product'
import { fpFix } from '../helpers'
import money from '../money'

export * from './product_type'
export * from './size'

export type FilterObject = FilterValue & {
  type: string
  params?: { price: PriceRangeFilter }
}

// export enum FilterType {
//   PRICE = 'price',
//   COLOR = 'color',
//   PRODUCT_TYP = 'product_type',
// }

/* eslint-disable import/prefer-default-export */
const COLORS_SET = [
  {
    color_name_en: 'Black',
    color_base_name: 'Black',
  },
  {
    color_name_en: 'Dark Blue',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Light Blue',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Slate gray',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Navy',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Blue',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Dark Slate Gray',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Azure',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Teal',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Cyan',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Turquoise',
    color_base_name: 'Blue',
  },
  {
    color_name_en: 'Bronze',
    color_base_name: 'Brown',
  },
  {
    color_name_en: 'Dark Brown',
    color_base_name: 'Brown',
  },
  {
    color_name_en: 'Light Brown',
    color_base_name: 'Brown',
  },
  {
    color_name_en: 'Brown',
    color_base_name: 'Brown',
  },
  {
    color_name_en: 'Maroon',
    color_base_name: 'Brown',
  },
  {
    color_name_en: 'Dim Gray',
    color_base_name: 'Gray',
  },
  {
    color_name_en: 'Gray',
    color_base_name: 'Gray',
  },
  {
    color_name_en: 'Dark Gray',
    color_base_name: 'Gray',
  },
  {
    color_name_en: 'Silver',
    color_base_name: 'Gray',
  },
  {
    color_name_en: 'Light Gray',
    color_base_name: 'Gray',
  },
  {
    color_name_en: 'Dark Green',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Light Green',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Army Green',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Aquamarine',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Spring green',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Green',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Lime',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Chartreuse',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Lawn green',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Yellow-Green',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Olive',
    color_base_name: 'Green',
  },
  {
    color_name_en: 'Champagne',
    color_base_name: 'Khaki',
  },
  {
    color_name_en: 'Tan',
    color_base_name: 'Khaki',
  },
  {
    color_name_en: 'Leopard',
    color_base_name: 'Leopard',
  },
  {
    color_name_en: 'Multicolor',
    color_base_name: 'Multicolor',
  },
  {
    color_name_en: 'Orange',
    color_base_name: 'Orange',
  },
  {
    color_name_en: 'Coral',
    color_base_name: 'Orange',
  },
  {
    color_name_en: 'Orange-Red',
    color_base_name: 'Orange',
  },
  {
    color_name_en: 'Chocolate',
    color_base_name: 'Orange',
  },
  {
    color_name_en: 'Dusty Pink',
    color_base_name: 'Pink',
  },
  {
    color_name_en: 'Salmon',
    color_base_name: 'Pink',
  },
  {
    color_name_en: 'Misty Rose',
    color_base_name: 'Pink',
  },
  {
    color_name_en: 'Pink',
    color_base_name: 'Pink',
  },
  {
    color_name_en: 'Red-Violet',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Purple',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Magenta',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Plum',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Dark Violet',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Indigo',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Blue-Violet',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Lavender',
    color_base_name: 'Purple',
  },
  {
    color_name_en: 'Burgundy',
    color_base_name: 'Red',
  },
  {
    color_name_en: 'Red',
    color_base_name: 'Red',
  },
  {
    color_name_en: 'Crimson',
    color_base_name: 'Red',
  },
  {
    color_name_en: 'White Smoke',
    color_base_name: 'White',
  },
  {
    color_name_en: 'Apricot',
    color_base_name: 'White',
  },
  {
    color_name_en: 'Beige',
    color_base_name: 'White',
  },
  {
    color_name_en: 'Ivory',
    color_base_name: 'White',
  },
  {
    color_name_en: 'White',
    color_base_name: 'White',
  },
  {
    color_name_en: 'Yellow',
    color_base_name: 'Yellow',
  },
  {
    color_name_en: 'Gold',
    color_base_name: 'Yellow',
  },
]

export const COLOR_MAP = COLORS_SET.reduce<Record<string, string[]>>(
  (acc, i) => {
    const type = i.color_base_name
    if (!acc[type]) {
      acc[type] = []
    }
    acc[type].push(i.color_name_en)
    return acc
  },
  {}
)

export const COLOR_FILTERS_SET = Object.keys(COLOR_MAP).map<FilterObject>(
  (key) => {
    const params = COLOR_MAP[key].map<
      Record<'variantOption', VariantOptionFilter>
    >((i) => ({
      variantOption: {
        name: 'color',
        value: i,
      },
    }))

    return {
      count: 0,
      type: ProductFilterKeys.color,
      input: JSON.stringify(params),
      id: key,
      label: key,
      params,
    }
  }
)

const PRICE_MAX = 40
const PRICE_STEP = 9.99
const PRICE_GAP = 0.01
const priceSet = Array(Math.ceil(Number(PRICE_MAX) / (PRICE_STEP + PRICE_GAP)))
  .fill(0)
  .reduce<Array<FilterObject>>((acc, cur, idx) => {
    let min = acc[idx - 1]?.params?.price.max || 0
    if (min) min = fpFix(min + PRICE_GAP)

    const params = {
      price: {
        min,
        max: fpFix(min + PRICE_STEP),
      },
    }

    acc.push({
      count: 0,
      type: ProductFilterKeys.price,
      input: JSON.stringify(params),
      id: String(idx * PRICE_STEP),
      label: `${money(params.price.min).formatPrice(2)}-${money(
        params.price.max
      ).formatPrice(2)}`,
      params,
    })
    return acc
  }, [])
priceSet.push({
  count: 0,
  type: ProductFilterKeys.price,
  label: `Over ${money(PRICE_MAX).formatPrice(2)}`,
  id: String(PRICE_MAX),
  input: JSON.stringify({
    price: {
      min: PRICE_MAX,
    },
  }),
  params: {
    price: {
      min: PRICE_MAX,
    },
  },
})
export const PRICE_FILTER_SET = priceSet
