export interface ProductFilterIntereface {
  productType: string
  title: string
  availableForSale: boolean
  tag: string[]
  'variants.price': {
    max: number
    min: number
  }
}

export type ProductFilter = Partial<ProductFilterIntereface>

export enum Connectives {
  and = 'AND',
  or = 'OR',
}

export enum CollectionDisjunctive {
  /** 0 */
  and = 'AND',
  /** 1 */
  or = 'OR',
}

export enum ProductFilterKeys {
  type = 'product_type',
  color = 'color',
  size = 'size',
  price = 'price',
  vendor = 'vendor',
}
