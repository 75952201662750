import React, { PropsWithChildren, ReactNode } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'

interface Props {
  spinning?: boolean
  indicator?: ReactNode
  size?: number
}

export default function Spin(props: PropsWithChildren<Props>) {
  const { spinning = true, size = 24, children, indicator } = props

  const Spiner = () =>
    spinning ? (
      <div className="absolute w-full top-0 bottom-0 bg-white bg-opacity-40 z-10 flex items-center justify-center">
        {indicator || (
          <span className="animate-spin inline-block">
            <AiOutlineLoading size={size} />
          </span>
        )}
      </div>
    ) : null

  if (children) {
    return (
      <div className="relative">
        <Spiner />
        {children}
      </div>
    )
  }

  return <Spiner />
}
