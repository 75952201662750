import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import Icon from './Icon'

interface Props {
  open?: boolean
  onClose?: () => void
}
const MAIL_URL = 'Mailto:support@bloomchic.us'
const MAIL_BODY = `?body=${'Full Name: %0D%0A Message:'}`
const Discount: FC<Props> = ({ open = true, onClose }) => (
  <Dialog open={open} onClose={() => onClose?.()} className="send-email">
    <div className="sendmail-wrapper py-8 px-6 text-12 text-gray-500 w-76">
      <div className="inline-block absolute top-1 right-1 cursor-pointer">
        <Icon name="close" width={28} height={28} onClick={() => onClose?.()} />
      </div>
      <div className="text-center font-semibold text-18 text-black leading-1.2">
        You are redirected to...
      </div>
      <div className="mt-3 text-center">
        Dear Customer, please send us an email to support@bloomchic.us if you
        need anything we can help. It will be preferred if you can attach the
        related page links, the relative screenshots, or a description of the
        issue you came across.
      </div>

      <a href={MAIL_URL + MAIL_BODY}>
        <div className="cursor-pointer w-44 h-10 mx-auto flex justify-center items-center font-semibold bg-black text-white mt-6 text-16">
          Send Email
        </div>
      </a>
    </div>
  </Dialog>
)

export default Discount
