import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'

import config from '../config'

interface globalData {
  trustpilot?: {
    count: number
    average: number
    type?: string
  }
}

export const GlobalDataContext = createContext({} as globalData)

export const GlobalDataProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [trustpilot, setTtrustpilot] = useState()
  const fetchTrustpilotData = async () => {
    const data = await fetch(
      `${config.api_url}/trustpilot/get`
    )
    const result: any = await data.json()
    setTtrustpilot(result.body)
  }
  useEffect(() => {
    // @ts-ignore
    !trustpilot && fetchTrustpilotData()
  }, [])

  return (
    <GlobalDataContext.Provider value={{ trustpilot } as globalData}>
      {children}
    </GlobalDataContext.Provider>
  )
}
